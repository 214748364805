import React, { useState } from "react";
import { Box, Button, } from "@mui/material";
import useFetchPromise from "../../../Components/CommonUtility/useFetchPromise";
import { useUser } from "../../../Components/CommonUtility/UserContext";
import { RepackBox } from "../Types/RepackBox";
import { RepackSeriesType } from "../Types/RepackSeriesType";
import SaveSeriesModal from "./SaveSeriesModal";
import { RepackBoxType } from "../Types/RepackBoxType";
import { RepackTicketType } from "../Types/RepackTicketType";

interface SeriesInfoPanelProps {
  boxTypes: RepackBoxType[];
  setSelectedType: React.Dispatch<
    React.SetStateAction<RepackSeriesType>
  >;
  selectedType: RepackSeriesType;
  boxes: RepackBox[];
  ticketTypes: RepackTicketType[];
}

const SeriesInfoPanel: React.FC<SeriesInfoPanelProps> = ({
  boxTypes,
  setSelectedType,
  selectedType,
  boxes,
  ticketTypes,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user } = useUser();
  const fetchPromise = useFetchPromise();

  const handleStageSave = () => {
    setIsModalOpen(true);
  };

  const handleConfirmSave = () => {
    const filteredBox = boxes.map((box: RepackBox) => [
      box.tickets?.map((ticket) => ticket.id),
    ]);

    fetchPromise("/Repack/construct/series", "POST", {
      Body: { boxes: filteredBox },
      UserId: user["id"],
    }).then(() => {
      setIsModalOpen(false);
    });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Box sx={{ width: "200px"}}>
      <Button onClick={handleStageSave} fullWidth variant="contained">
        Save New Series
      </Button>

      <SaveSeriesModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleConfirmSave}
        boxTypes={boxTypes}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        boxes={boxes}
        ticketTypes={ticketTypes}
      />
    </Box>
  );
};

export default SeriesInfoPanel;
