import { Box, Divider, Tab, Tabs, Typography } from '@mui/material';
import { ShowsProvider } from '../Contexts/ShowContexts/Shows/ShowsContext';
import { SingleShowProvider } from '../Contexts/ShowContexts/SingleShow/SingleShowContext';
import SingleShowPage from './SingleShowPage';
import ShowsDataGrid from '../Components/ShowComponents/ShowsDataGrid';
import BulkShowUploader from '../Components/ShowComponents/BulkShowUploader';
import { FileUploaderProvider } from '../Components/FileUploader/FileUploaderContext';
import { ShowProvider } from '../Contexts/ShowContexts/Shows/ShowContext';
import { UploadTasksData } from '../Components/ShowFileUploadStatus/UploadTasksData';
import React from 'react';
type ShowPageProps = {
    pageType?: string
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const ShowPage = ({ pageType} : ShowPageProps)  => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };
    if (pageType == "single") {
        const path = window.location.pathname.split("/")[3]
        return(
             <SingleShowProvider showPublicId={path} >
                <SingleShowPage />
             </SingleShowProvider>
        )
    }
    return(
    <Box
        sx={{
            width: '%',
            height: '100%',
            padding: '5px',
            display: 'flex',
            flexDirection: 'column'
        }}
        >
        <Box sx={{
            width: '100%',
            height: '40px',
            display: 'flex',
            flexDirection: 'row',
        }}>
            <Typography variant="h4" sx={{marginBottom: '3px'}}>Show Manager Page</Typography>
            <FileUploaderProvider endpoint={`/show/bulkshowupload/`} accepts='text/csv' maxFiles={100}>
                <ShowProvider>            
                    <BulkShowUploader />
                </ShowProvider>
            </FileUploaderProvider>
        </Box>


        <Divider sx={{borderBottomWidth: 3}}/>

        <Box sx={{ width: '100%', height: '100%'}}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Shows" {...a11yProps(0)} />
          <Tab label="Uploads" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
      <ShowsProvider>
            <ShowsDataGrid/>
        </ShowsProvider>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      <UploadTasksData/>

      </CustomTabPanel>
    </Box>

    </Box>)
}
  
  export default ShowPage;
  