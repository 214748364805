import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { DataGridPro, GridRowId, GridRowParams, GridToolbarContainer } from "@mui/x-data-grid-pro";
import Button from "@mui/material/Button";
import { Box, Grid } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CollapsiblePaper from "../../../Components/Common/CollapsiblePaper";
import { useSnackbar } from "../../../Contexts/SnackbarContext/SnackbarContext";
import { useBuildRepacks } from "../../../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksContext";
import BoxGrid from "./StagedBoxesPreview/Components/BoxGrid/BoxGrid";
import { useBoxActions } from "./StagedBoxesPreview/Hooks/useBoxActions"
import { useItemActions } from "./StagedBoxesPreview/Hooks/useItemActions";
import TicketSelectionDialog from "./TicketSelectionDialog";
import { RepackItem } from "../Types/RepackItem";
import { useUser } from "../../../Components/CommonUtility/UserContext";
import { RepackBox } from "../Types/RepackBox";

const StagedCasesPreview = () => {
  const [searchParams] = useSearchParams();
  const [expandedRowIds, setExpandedRowIds] = useState<GridRowId[]>([]);
  const [ticketDialogOpen, setTicketDialogOpen] = useState(false);
  const [selectedRepackItem, setSelectedRepackItem] = useState<RepackItem | null>(null);
  const [boxExpandedRowIds, setBoxExpandedRowIds] = useState<GridRowId[]>([]);
  
  const seriesId = searchParams.get("id") || "";
  const { newSnackbarMessage } = useSnackbar();

  const {
    cases,
    selectedCase,
    setSelectedCase,
    createNewCase,
    updateCase,
    deleteCase,
    setSelectedSeriesId,
    setSelectedBox,
    boxes,
    setBoxes,
    setBox,
    setAllTickets,
    availableTickets,
  } = useBuildRepacks();
    const { user } = useUser();

  const boxActions = useBoxActions(user, boxes, setBoxes, newSnackbarMessage);
  const itemActions = useItemActions(user, setBox, (value) => setBoxes(value as RepackBox[]), setAllTickets, newSnackbarMessage, handleTicketDialogOpen);

  useEffect(() => {
    setSelectedSeriesId(seriesId);
  }, [expandedRowIds, setSelectedSeriesId, seriesId]);

  const handleCreateCase = async () => {
    try {
      await createNewCase({
        repackSeriesId: seriesId,
        isStaged: true,
      });
    } catch (error) {
      console.error("Failed to create case:", error);
      newSnackbarMessage("Error creating case.", "error");
    }
  };

  const handleDeleteCase = async () => {
    if (!selectedCase) {
      newSnackbarMessage("No case selected.", "error");
      return;
    }

    try {
      await deleteCase(selectedCase.publicId);
      setExpandedRowIds([]);
      setSelectedCase(null);
    } catch (error) {
      console.error("Failed to delete case:", error);
      newSnackbarMessage("Error deleting case.", "error");
    }
  };

  const handleRowClick = (params: GridRowParams) => {
    if (params.row.id === selectedCase?.id) {
      setExpandedRowIds([]);
      setSelectedCase(null);
      return;
    }

    const foundCase = cases.find((rc) => rc.publicId === params.id);
    if (!foundCase) return;
    
    setSelectedCase(params.row);
    setExpandedRowIds([params.id]);
  };

  const handleRowExpandChange = (ids: GridRowId[]) => {
    if (ids.length === 1) {
      const expandedId = ids[0];
      const foundCase = cases.find((rc) => rc.publicId === expandedId);

      setExpandedRowIds([expandedId]);
      if (foundCase) {
        setSelectedCase(foundCase);
      }
    } else {
      setExpandedRowIds([]);
      setSelectedCase(null);
    }
  };

  const handleUpdateCase = async (updatedRow: any) => {
    try {
      if (!selectedCase) {
        throw new Error("No case selected for update");
      }

      const caseToUpdate = {
        ...selectedCase,
        serialNumber: updatedRow.serialNumber || "",
        publicId: updatedRow.publicId,
        repackSeriesId: Number(seriesId),
        isStaged: true,
        id: selectedCase.id,
        productId: selectedCase.productId,
        boxes: selectedCase.boxes || [],
        repackCaseTypeId: selectedCase.repackCaseTypeId,
        repackCaseType: selectedCase.repackCaseType
      };

      await updateCase(updatedRow.publicId, caseToUpdate);
      return updatedRow;
    } catch (error) {
      console.error("Failed to update case:", error);
      newSnackbarMessage("Error updating case.", "error");
      return null;
    }
  };

  function handleTicketDialogOpen(item: RepackItem) {
    setSelectedRepackItem(item);
    setTicketDialogOpen(true);
  }

  const handleTicketSelect = async () => {
    if (!selectedRepackItem) return;

    try {
      // Implementation similar to StagedBoxesPreview
      setTicketDialogOpen(false);
      setSelectedRepackItem(null);
    } catch (error) {
      console.error("Error adding ticket to card:", error);
      newSnackbarMessage("Failed to add ticket to card", "error");
    }
  };

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
        <Grid item>
          <Button variant="text" onClick={handleCreateCase}>
            Add New Case
          </Button>
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );

  const getDetailPanelContent = () => {
    return (
      <Grid container direction="column" spacing={2}>
        <Grid item xs={12}>
          <BoxGrid
            expandedRowIds={boxExpandedRowIds}
            onRowExpandChange={setBoxExpandedRowIds}
            onBoxSelection={(id) => {
              const selectedBox = boxes?.find(box => box.id === id);
              setSelectedBox(selectedBox || null);
            }}
            boxActions={boxActions}
            itemActions={itemActions}
            seriesId={seriesId}
          />
        </Grid>
        <Grid item>
          <Button variant="text" onClick={handleDeleteCase}>
            <DeleteIcon />
            Delete Case
          </Button>
        </Grid>
      </Grid>
    );
  };

  const caseColumns = [
    {
      field: "rowNumber",
      headerName: "#",
      width: 100,
      renderCell: (params: any) =>
        params.api.getSortedRowIds().indexOf(params.id) + 1,
    },
    {
      field: "serialNumber",
      headerName: "Serial Number",
      width: 150,
      editable: true,
    },
    {
      field: "boxCount",
      headerName: "Box Count",
      width: 120,
      valueGetter: (params: any) => params.row.boxes?.length || 0,
    },
    // Additional columns as needed
  ];

  return (
    <CollapsiblePaper
      title="Staged Cases Preview"
      closeTitle="Close Preview"
      openTitle="Open Preview"
    >
      <Box sx={{ height: "auto", minHeight: "400px", width: "100%" }}>
        <DataGridPro
          getRowId={(row) => row.publicId || row.id}
          rows={cases}
          columns={caseColumns}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={() => 400}
          detailPanelExpandedRowIds={expandedRowIds}
          onDetailPanelExpandedRowIdsChange={handleRowExpandChange}
          disableMultipleRowSelection={true}
          onRowClick={handleRowClick}
          processRowUpdate={handleUpdateCase}
          onProcessRowUpdateError={(error) => {
            console.error("Error processing case update:", error);
            newSnackbarMessage("Error processing case update.", "error");
          }}
          components={{ Toolbar: CustomToolbar }}
        />
      </Box>
      <TicketSelectionDialog
        open={ticketDialogOpen}
        onClose={() => setTicketDialogOpen(false)}
        onSelect={handleTicketSelect}
        availableTickets={availableTickets}
      />
    </CollapsiblePaper>
  );
};

export default StagedCasesPreview;