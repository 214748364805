import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { ChannelInfo } from "../../Types/DataModels/ShowChannel/TickerChannelInfo";
import useFetch from "../../Components/CommonUtility/useFetch";
import { useLiveUpdateDataSource } from "../../Utilities/LiveUpdateDataSource/LiveDatasource";
import { GridRowSelectionModel } from "@mui/x-data-grid-premium";
import useFetchPromise from "../../Components/CommonUtility/useFetchPromise";
import { useUser } from "../../Components/CommonUtility/UserContext";
import { TickerData } from "../../Types/DataModels/Ticker/TickerData";

type TickerDataStoreContextProps = {
  children: React.ReactNode;
}

type TickerDataStoreProviderProps = {
  rows: TickerData[];
  channels: ChannelInfo[];
  isLoading: boolean;
  selectedTab: string;
  setSelectedTab: (value: string) => void;
  selectedRows: GridRowSelectionModel;
  setSelectedRows: (value: GridRowSelectionModel) => void;
  deleteTickers: (id: string | number | undefined) => void;
  LoadingDisplay: React.ReactNode;
}


const TickerDataStoreContext = createContext<TickerDataStoreProviderProps | null>(null);
export const TickerDataStoreProvider = ({ children }: TickerDataStoreContextProps) => {
  
  const { data: originalRows, loading: rowsLoading, LoadingDisplay } = useLiveUpdateDataSource<TickerData>(
    {baseUrl: "/Ticker/getAll", hubUrl: "/hubs/ticker", defaultData: [], groups: ["all"]}
  );
  const { user} = useUser();
  const { data: channels, loading: channelsLoading } = useFetch("/Show/channels/all", true, []);

  const [selectedTab, setSelectedTab] = useState("all");

  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);

  const [rows, setRows] = useState<TickerData[]>([]);
  const fetchPromise = useFetchPromise();

  useEffect(() => {
    setRows(originalRows);
  }, [originalRows]);

  useEffect(() => {
    if(originalRows.length === 0){
      return;
    }
    if(selectedTab !== "all"){
      setRows(originalRows.filter(row => row.channels.some(channel => channel.channelName === selectedTab)));
    }
    else{
      setRows(originalRows);
    }

  }, [originalRows, selectedTab]);


  const deleteTickers = async (id: number| string | undefined)=>{
    const ids = id ? [id] : selectedRows;
    const filteredRows = rows.filter(row => ids.includes(row.id));
    const guidsToSend = filteredRows.map(row => row.publicId);
    await fetchPromise("/Ticker/delete", "POST", {
      Tickers: guidsToSend,
      UserId: user["id"],
    });
  }


  const value = useMemo(() => {
    return {
      rows: rows || [],
      channels: channels || [],
      isLoading: rowsLoading || channelsLoading,
      selectedTab,
      setSelectedTab,
      selectedRows,
      setSelectedRows,
      deleteTickers,
      LoadingDisplay
    }
  }, [rows, channels, selectedTab, setSelectedTab, selectedRows, setSelectedRows, deleteTickers, LoadingDisplay]);

  return (
  <TickerDataStoreContext.Provider value={value}>
    {children}
  </TickerDataStoreContext.Provider>);
};

export function useTickerDataStore() {
  const context = useContext(TickerDataStoreContext);
  if (!context) {
    throw new Error("Invalid TickerDataStoreContext, must be wrapped in TickerDataStoreProvider");
  }
  return context;
}
