import { Tooltip, Typography } from "@mui/material";

export enum DateTimePrecision {
  DAY = 'day',
  HOUR = 'hour',
  MINUTE = 'minute',
  SECOND = 'second'
}
export type DateTimeRenderProps = {
  value: Date;
  format?: string;
  precision?: DateTimePrecision;
}


export const DateTimeRender = (props: DateTimeRenderProps) => {
  const { value, format } = props;
  
  const getRelativeTimeString = () => {
    const now = new Date();
    const diffMs = now.getTime() - value.getTime();
    const diffMinutes = Math.floor(diffMs / (1000 * 60));
    const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));

    const monthDiff = (now.getFullYear() - value.getFullYear()) * 12 + 
                     (now.getMonth() - value.getMonth());
    const adjustedMonthDiff = now.getDate() < value.getDate() ? monthDiff - 1 : monthDiff;

    const yearDiff = Math.floor(adjustedMonthDiff / 12);

    if (diffMinutes < 60) {
      return `${diffMinutes} minute${diffMinutes !== 1 ? 's' : ''} ago`;
    } else if (diffHours < 24) {
      return `${diffHours} hour${diffHours !== 1 ? 's' : ''} ago`;
    } else if (diffDays < 30) {
      return `${diffDays} day${diffDays !== 1 ? 's' : ''} ago`;
    } else if (adjustedMonthDiff < 12) {
      return `${adjustedMonthDiff} month${adjustedMonthDiff !== 1 ? 's' : ''} ago`;
    } else {
      return `${yearDiff} year${yearDiff !== 1 ? 's' : ''} ago`;
    }
  };

  return (
    value ?
    <Tooltip title={getRelativeTimeString()} arrow>
      <Typography>{value.toLocaleDateString(format)}</Typography>
    </Tooltip>
    :
    <Typography></Typography>
  );
}