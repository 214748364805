import { useCallback, useEffect, useState } from "react";
import { useUser } from "../CommonUtility/UserContext";
import ModalOpenerButton from "../Common/ModalOpenerButton";
import { Button, Dialog, DialogActions, DialogContent, Grid } from "@mui/material";
import { useFileUploader } from "../FileUploader/FileUploaderContext";
import SimpleFileUploader from "../FileUploader/SimpleFileUploader";
import PlatformAutoComplete from "../PlatformComponents/PlatformAutocomplete";
import ShowChannelAutocomplete from "../ShowChannelComponents/ShowChannelAutocomplete";
import { useShow } from "../../Contexts/ShowContexts/Shows/ShowContext";
import FetchModule from '../../Modules/FetchModule';
import { useSnackbar } from "../../Contexts/SnackbarContext/SnackbarContext";
import { useLocation } from "react-router-dom";
import { ShowFileUploadStatuses } from "../ShowFileUploadStatus/ShowFileUploadStatuses";
const fetchData = new FetchModule();

const BulkShowUploader = () => {
    const [open, setOpen] = useState(false);
    const {user}: {user: any} = useUser();
    const {platform, setPlatform, channel} = useShow();
    const {selectedFiles, uploading, setUploading, endpoint} = useFileUploader();
    const {newSnackbarMessage} = useSnackbar();

    const [uploadTaskId, setUploadTaskId] = useState<string | null>(null);
    const { search } = useLocation();

    useEffect(() => {
        const parameters = new URLSearchParams(search);
        const id = parameters.get('uploadTaskId');
        setUploadTaskId(id)
    }, [])
    const isDisabled = useCallback(
        () => {
            return platform == null || channel == null || selectedFiles == null || selectedFiles.length == 0
        },
        [platform, channel, selectedFiles]
    )
    const uploadFile = async () => {
        try {
            if (!uploading && !isDisabled()) {
                setUploading(true)
                const newEndpoint = endpoint + channel.publicId+"/"+user?.id;
                const response = await fetchData.filesFormData(newEndpoint, "POST", selectedFiles);
                if (response.ok) {
                    newSnackbarMessage("File(s) uploaded successfully", "success");
                    const data = await response.json();
                    const taskId = data.uploadTaskPublicId
                    if (taskId != null || taskId != undefined) {
                        //navigate(taskId)
                        const parameters = new URLSearchParams(search);
                        parameters.set('uploadTaskId', taskId);
                        setUploadTaskId(taskId)
                    }
                    
                } else {
                    newSnackbarMessage("Error uploading file(s)", "error");
                }
                setUploading(false)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setUploading(false)
            setOpen(false)
        }

    }

    return (
        <>
        <ModalOpenerButton text="Bulk Upload Shows" setOpen={() => setOpen(true)} icon='edit' />
        <Dialog open={open} onClose={() => setOpen(false)}>

            <DialogContent>
                <Grid spacing={5}>
                    <Grid item xs={12}>
                        <PlatformAutoComplete 
                            platform={platform}
                            setPlatform={setPlatform}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ShowChannelAutocomplete/>
                    </Grid>
                    <Grid item xs={12}>
                        <SimpleFileUploader uploadHandledExternally={true}/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={isDisabled() || uploading} onClick={uploadFile}>Upload</Button>
            </DialogActions>
        </Dialog>
        {uploadTaskId != undefined ? 
        <ShowFileUploadStatuses uploadTaskId={uploadTaskId}
            titleName={channel && platform ? "Uploads for " + channel.channelName +"-" + platform.platformName : undefined}
            /> : <></>}
        </>
    )
}

export default BulkShowUploader;
