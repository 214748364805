import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { DataGridPremium, GridColDef, GridRenderCellParams, GridRenderEditCellParams, GridToolbarColumnsButton, GridToolbarDensitySelector, GridToolbarContainer, GridToolbarFilterButton, GridToolbarExport } from "@mui/x-data-grid-premium";
import { useAddTickerModal } from "./UseTickerCreator";
import { useTickerDataStore } from "./TickerDataStore";
import TickerTabs from "./TickerTabs";
import dayjs from "dayjs";
import useFetchPromise from "../../Components/CommonUtility/useFetchPromise";
import { useUser } from "../../Components/CommonUtility/UserContext";
import { CheckboxSelect } from "../../Components/Common/CheckboxSelect";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useFeatureFlags } from "../../Contexts/FeatureFlagContexts/FeatureFlagContext";
import DeleteIcon from '@mui/icons-material/Delete';
import { ChannelInfo } from "../../Types/DataModels/ShowChannel/TickerChannelInfo";
import { TickerData } from "../../Types/DataModels/Ticker/TickerData";


const CustomToolbar = () => {
  const {LoadingDisplay} = useTickerDataStore();
  const {setIsTickerCreatorOpen} = useAddTickerModal();
  return (
    <GridToolbarContainer>
      
      <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "row", alignItems: "left"}}>
      {LoadingDisplay}
      <GridToolbarColumnsButton 
        slotProps={{ tooltip: { title: 'Change displayed columns' } }}
      />
      <GridToolbarFilterButton 
        slotProps={{ tooltip: { title: 'Apply filters' } }}
      />
      <GridToolbarDensitySelector
        slotProps={{ tooltip: { title: 'Change row spacing' } }}
      />
      <GridToolbarExport
        slotProps={{
          tooltip: { title: 'Export data' },
        }}
        excelOptions={{
          fields: ['text', 'channels', 'visible'],
          fileName:"TickerExport"+dayjs().format("MMDDYYYYhhmm")+".xlsx"
        }}
      />
      <Tooltip title="Add New Ticker">
        <Button 
          color="primary" 
          size="small"
          onClick={() => setIsTickerCreatorOpen(true)}
        >
          Add New Ticker
        </Button>
      </Tooltip>
      
      </Box>
  </GridToolbarContainer>
  );
};

const ChannelSelect = (params: GridRenderEditCellParams) => {

  const {channels} = useTickerDataStore();
  return (
    <Box
      className={"filter-autocomplete"}
      sx={{
      }}
    >
      <CheckboxSelect
          
        options={channels}
        value={params.item.value}
        onChange={(newValue) => {
          const saveObj = {
            field: params.item.field,
            operator: params.item.operator,
            value: newValue,
            id: params.item.id
          } 
          params.apiRef.current.upsertFilterItem(saveObj);
        }}
        hasFocus={params.hasFocus}
      />
    </Box>
    
  );
}

const TickerLanding = () => {
  const {rows, channels, setSelectedRows, deleteTickers} = useTickerDataStore();
  const fetchPromise = useFetchPromise();
  const {user} = useUser();
  const {flags} = useFeatureFlags();

  const columns: GridColDef[] = [
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <IconButton
          color="error"
          size="small"
          onClick={() => deleteTickers(params.id)}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
    {
      field: "text",
      headerName: "Ticker Text",
      flex: 1,
      editable: true
    },
    {
      field: "channels",
      headerName: "Channels",
      flex: 1,
      editable: true,
      renderCell: (params: GridRenderCellParams) => {
        if(params.row.channels.length === channels.length){
          return "All";
        }
        return params.row.channels.map((channel: ChannelInfo) => channel.channelName).join(", ");
      },
      renderEditCell: (params: GridRenderEditCellParams) => (
        <CheckboxSelect
          {...params}
          options={channels}  
          value={params.value || []} 
          onChange={(newValue) => {
            params.api.setEditCellValue({ 
              id: params.id, 
              field: params.field, 
              value: newValue 
            });
          }}
          displayField={"channelName"}
        />
      ),
      filterOperators: [
        {
          label: 'Has Channels',
          value: 'hasChannels',
          getApplyFilterFn: () => {
            return (params) =>params?.length > 0;
          }
        },
        {
          label: 'Has No Channels',
          value: 'hasNoChannels',
          getApplyFilterFn: () => {
            return (params) => params?.length === 0;
          }
        },
        {
          label: 'Has All Channels',
          value: 'hasAllChannels',
          getApplyFilterFn: () => {
            return (params) => params.length === channels.length;
            
          }
        },
        {
          label: 'Has',
          value: 'has',
          InputComponent: ChannelSelect,
          getApplyFilterFn: (filterItem) => {
            
            return (params) => {
              if(!filterItem.value){
                return false;
              }
              const selectedChannelIds = filterItem.value.map((c: ChannelInfo) => c.publicId);
              return params.some((channel: ChannelInfo) => selectedChannelIds.includes(channel.publicId)
              );
            };
          }
        }
      ],
      valueFormatter: (params: ChannelInfo[]) => {
        if(!params) return "";
        return params.map((channel: ChannelInfo) => channel.channelName).join(', ');
      }
    },
    ...(flags.scheduleTicker ? [
      {
        field: "scheduleStartTime",
        headerName: "Schedule Start Time",
        flex: 1,
        editable: true,
        renderCell: (params: GridRenderCellParams) => {
          return params?.value ? dayjs(params.value).format("MM/DD/YYYY hh:mm A") : "";
        },
        renderEditCell: (params: GridRenderCellParams) => (
          <DateTimePicker
            value={params.value ? dayjs(params.value) : null}
            onChange={(value) => params.api.setEditCellValue({ 
              id: params.id, 
              field: params.field, 
              value: value 
            })}
            views={['year', 'month', 'day', 'hours']}
          />
        )
      },
      {
        field: "scheduleEndTime",
        headerName: "Schedule End Time",
        flex: 1,
        editable: true,
        renderCell: (params: GridRenderCellParams) => {
          return params?.value ? dayjs(params.value).format("MM/DD/YYYY hh:mm A") : "";
        },
        renderEditCell: (params: GridRenderCellParams) => (
          <DateTimePicker
            value={params.value}
            onChange={(value) => params.api.setEditCellValue({ 
              id: params.id, 
              field: params.field, 
              value: value 
            })}
            views={['year', 'month', 'day', 'hours']}
          />
        )
      }
    ] : []),
    {
      field: "visible",
      headerName: "Enabled",
      flex: 1,
      type: "boolean",
      editable: true,
      renderCell: (params: GridRenderCellParams) => {
        return params.row.visible ? "Enabled" : "Disabled";
      }
    }
  ];

  const handleRowUpdate = async (newRow: TickerData) => {
    const ticker = newRow;
    await fetchPromise("/Ticker/update", "PUT", {
      UserId: user["id"],
      Guid: newRow.publicId,
      ...ticker,
      channels: ticker.channels.map((channel: ChannelInfo) => channel.publicId)
    });
    return newRow; // Return the updated row data
  }
  return (
    <Box sx={{ height: "80%", width: "80%",
      padding: "5px", }}>
      <TickerTabs/>
      <DataGridPremium
        rows={rows}
        columns={columns}
        slots={{
          toolbar: CustomToolbar
        }}
        editMode="row"
        processRowUpdate={handleRowUpdate}
        checkboxSelection
        onRowSelectionModelChange={(newSelection) => {
          setSelectedRows(newSelection);
        }}
      />
    </Box>
  );
};

export default TickerLanding;
