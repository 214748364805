import React, { useRef, useEffect } from 'react';
import { Tooltip, TooltipProps,  } from '@mui/material';
import { styled } from '@mui/material/styles';

interface InteractiveTooltipProps {
  children: React.ReactElement;
  bufferPx?: number;
  value?: any;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const StyledTooltip = styled(({ className, ...props }: { className?: string } & TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.divider}`,
    boxShadow: theme.shadows[2],
    padding: theme.spacing(1),
    maxWidth: 'none',
    margin: 0
  },
  [`& .MuiTooltip-arrow`]: {
    color: theme.palette.background.paper,
    '&::before': {
      border: `1px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.background.paper
    }
  }
}));

export const InteractiveTooltip: React.FC<InteractiveTooltipProps> = ({
  children,
  bufferPx = 16,
  value,
  open,
  setOpen
}) => {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);

  /*
   This adds some wiggle room to the tooltip so that it doesn't close when the mouse is near the edge of the tooltip.
  */
  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (!open) return;

      const tooltipElement = tooltipRef.current;
      const triggerElement = triggerRef.current;

      if (!tooltipElement || !triggerElement) return;

      const tooltipRect = tooltipElement.getBoundingClientRect();
      const triggerRect = triggerElement.getBoundingClientRect();

      const isInTooltipArea = (
        e.clientX >= tooltipRect.left - bufferPx &&
        e.clientX <= tooltipRect.right + bufferPx &&
        e.clientY >= tooltipRect.top - bufferPx &&
        e.clientY <= tooltipRect.bottom + bufferPx
      );

      const isInTriggerArea = (
        e.clientX >= triggerRect.left - bufferPx &&
        e.clientX <= triggerRect.right + bufferPx &&
        e.clientY >= triggerRect.top - bufferPx &&
        e.clientY <= triggerRect.bottom + bufferPx
      );

      if (!isInTooltipArea && !isInTriggerArea) {
        setOpen(false);
      }
    };

    document.addEventListener('mousemove', handleMouseMove);
    return () => document.removeEventListener('mousemove', handleMouseMove);
  }, [open, bufferPx]);
  return (
    <div ref={triggerRef}>
      <StyledTooltip
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        title={
          <div ref={tooltipRef}>
            {children}
          </div>
        }
        arrow
        PopperProps={{
          sx: {
            '& .MuiTooltip-arrow': {
              marginTop: '-0.5px'
            }
          }
        }}
      >
        <span style={{ display: 'inline-block' }}>{value}</span>
      </StyledTooltip>
    </div>
  );
};
