import { Box, Typography, Paper, Grid, Divider } from "@mui/material";
import { InteractiveTooltip } from "../../../Components/InteractiveTooltip/InteractiveTooltip";
import { useState } from "react";
import { GridCellParams } from "@mui/x-data-grid-pro";
import { Card } from "../Types/CardType";

interface QuarterData {
  title: string;
  averagePrice: number;
  amount: number;
}

const QuarterSection = ({ title, averagePrice, amount }: QuarterData) => (
  <Box 
    sx={{ 
      p: 0.75, 
      textAlign: 'center', 
      width: '100%',
      transition: 'background-color 0.2s',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.02)'
      }
    }}
  >
    <Typography variant="caption" sx={{ fontWeight: 600, mb: 0.25 }}>{title}</Typography>
    <Typography variant="body2" sx={{ color: 'primary.main', fontWeight: 500 }}>${averagePrice.toFixed(2)}</Typography>
    <Typography variant="caption" sx={{ color: 'text.secondary' }}>Qty: {amount}</Typography>
  </Box>
);

const Window = (props: Card)=>{
  const data = props.cardLadderCertData
  if(!data) return <Typography>No data from card ladder exists, please verify the cert is valid</Typography>;
  return (
    <Paper 
      elevation={3}
      sx={{ 
        width: 200, 
        p: 1.25,
        borderRadius: 1,
        '& .MuiDivider-root': {
          my: 1,
          opacity: 0.5
        }
      }}
    >
      <Box 
        sx={{ 
          mb: 1.25,
          p: 1,
          backgroundColor: 'rgba(0, 0, 0, 0.02)',
          borderRadius: 1,
          border: '1px solid',
          borderColor: 'divider'
        }}
      >
        <Typography variant="caption" sx={{ fontWeight: 600, mb: 0.75, display: 'block', color: 'text.secondary' }}>
          Card Details
        </Typography>
        <Typography variant="caption" sx={{ lineHeight: 1.25, display: 'block' }}>{data.description}</Typography>
      </Box>
      
      <Box 
        sx={{ 
          mb: 1.25,
          p: 1,
          backgroundColor: 'rgba(0, 0, 0, 0.02)',
          borderRadius: 1,
          border: '1px solid',
          borderColor: 'divider'
        }}
      >
        <Typography variant="caption" sx={{ fontWeight: 600, mb: 0.75, display: 'block', color: 'text.secondary' }}>
          Market Info
        </Typography>
        <Typography variant="caption" sx={{ display: 'block', mb: 0.5 }}>
          Estimated Value: <Typography component="span" variant="caption" sx={{ fontWeight: 500 }}>${data.estimatedValue.toLocaleString()}</Typography>
        </Typography>
        <Typography variant="caption" sx={{ display: 'block', mb: 0.5 }}>
          Last Sale: <Typography component="span" variant="caption" sx={{ fontWeight: 500 }}>{data.lastSaleDate ? new Date(data.lastSaleDate).toLocaleDateString() : 'N/A'}</Typography>
        </Typography>
        <Typography variant="caption" sx={{ display: 'block' }}>
          Population: <Typography component="span" variant="caption" sx={{ fontWeight: 500 }}>{data.population}</Typography>
        </Typography>
      </Box>
      
      <Divider />
      
      <Box sx={{ mb: 0.75 }}>
        <Typography variant="caption" sx={{ fontWeight: 600, mb: 0.5, color: 'text.secondary' }}>
          Sales History
        </Typography>
        <Grid 
          container 
          spacing={0} 
          sx={{
            width: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.02)',
            borderRadius: 1,
            p: 0.5,
            position: 'relative'
          }}
        >
          <Grid item xs={6} sx={{ pr: 0.75 }}>
            <Box>
              <Box sx={{ 
                borderBottom: '1px solid',
                borderColor: 'divider'
              }}>
                <QuarterSection title="2 Weeks" averagePrice={data.twoWeek.averagePrice} amount={data.twoWeek.velocity} />
              </Box>
              <Box>
                <QuarterSection title="1 Month" averagePrice={data.oneMonth.averagePrice} amount={data.oneMonth.velocity} />
              </Box>
            </Box>
          </Grid>
          
          <Divider 
            orientation="vertical" 
            flexItem 
            sx={{ 
              position: 'absolute',
              left: '50%',
              height: '100%',
              transform: 'translateX(-50%)',
              opacity: 0.5,
              color: 'divider'
            }} 
          />
          
          <Grid item xs={6} sx={{ pl: 0.75 }}>
            <Box>
              <Box sx={{ 
                borderBottom: '1px solid',
                borderColor: 'divider'
              }}>
                <QuarterSection title="1 Quarter" averagePrice={data.oneQuarter.averagePrice} amount={data.oneQuarter.velocity} />
              </Box>
              <Box>
                <QuarterSection title="1 Year" averagePrice={data.oneYear.averagePrice} amount={data.oneYear.velocity} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
} 

export const CardLadderHistoryTooltip = ({value, row}: GridCellParams) => {
  const [open, setOpen] = useState(false);
  
  
  return (
    <div>
      <InteractiveTooltip value={value} open={open} setOpen={setOpen}>
        <Window {...row}/>
      </InteractiveTooltip>
    </div>
  );
};
