import { LocalStorageKey } from "./LocalStorageEnum";
import { updateOrderWithNewColumnAfterField, updateVisibilityWithNewColumn, updateWidthWithNewColumn } from "./GenericDatagridMutators";
import { GetDefaultFilter, GetDefaultSort } from "../../Components/CommonUtility/ServerSide/ServerSideDefaults";


const compareVersions = (version1: string, version2: string) => {
  const version1Parts = version1.split('.').map(Number);
  const version2Parts = version2.split('.').map(Number);

  for (let i = 0; i < version1Parts.length; i++) {
    if (version1Parts[i] > version2Parts[i]) return 1;
    if (version1Parts[i] < version2Parts[i]) return -1;
  }
  return 0;
};

// Using a dictionary for versioned mutations
const mutationsDict: Record<string, Record<string, (data: any) => any>> = {
  "0.1.1": { // used for versioning unit tests
    "localStorageTest": (data) => {
      return {...data, newValue: true, value: 3}
    }
  },
  "1.0.0": {
    [LocalStorageKey.CARD_DASHBOARD_WIDTH]: (data) => {
      data = updateWidthWithNewColumn(data, "teams", 100);
      return updateWidthWithNewColumn(data, "flagged", 100);
    },
    [LocalStorageKey.CARD_DASHBOARD_ORDER]: (data) => {
      data = updateOrderWithNewColumnAfterField(data, "teams", "vendor");
      return updateOrderWithNewColumnAfterField(data, "flagged", "status");
    },
    [LocalStorageKey.CARD_DASHBOARD_VISIBILITY]: (data) => {
      data = updateVisibilityWithNewColumn(data, "teams", true);
      return updateVisibilityWithNewColumn(data, "flagged", false);
    },
    [LocalStorageKey.CARD_DASHBOARD_FILTERS]: () => {
      return GetDefaultFilter();
    },
    [LocalStorageKey.CARD_DASHBOARD_SORT]: () => {
      return GetDefaultSort();
    },
    
  },
  "1.16.2": {
      [LocalStorageKey.CARD_DASHBOARD_VISIBILITY]: (data) => {
        return updateVisibilityWithNewColumn(data, "submissionId", true);
      },
      [LocalStorageKey.CARD_DASHBOARD_ORDER]: (data) => {
        return updateOrderWithNewColumnAfterField(data, "submissionId", "certNumber");
      },
      [LocalStorageKey.CARD_DASHBOARD_WIDTH]: (data) => {
        return updateWidthWithNewColumn(data, "submissionId", 75);
      }
    },
  "1.27.0": {
    [LocalStorageKey.CARD_DASHBOARD_WIDTH]: (data) => {
      return updateWidthWithNewColumn(data, "compLastModified", 100);
    },
    [LocalStorageKey.CARD_DASHBOARD_ORDER]: (data) => {
      return updateOrderWithNewColumnAfterField(data, "compLastModified", "comp");
    },
    [LocalStorageKey.CARD_DASHBOARD_VISIBILITY]: (data) => {
      return updateVisibilityWithNewColumn(data, "compLastModified", true);
    },
  },
  "1.31.0": {
    
    [LocalStorageKey.CARD_DASHBOARD_ORDER]: (data) => {
      const a =  updateOrderWithNewColumnAfterField(data, "cardLadderComp", "compLastModified");
      const b =  updateOrderWithNewColumnAfterField(a, "cardLadderLastSale", "cardLadderComp");
      return updateOrderWithNewColumnAfterField(b, "cardLadderConfidence", "cardLadderLastSale");
    },
    [LocalStorageKey.CARD_DASHBOARD_WIDTH]: (data) => {
      const a =  updateWidthWithNewColumn(data, "cardLadderComp", 100);
      const b =  updateWidthWithNewColumn(a, "cardLadderLastSale", 100);
      return updateWidthWithNewColumn(b, "cardLadderConfidence", 100);
    },
    [LocalStorageKey.CARD_DASHBOARD_VISIBILITY]: (data) => {
      const a =  updateVisibilityWithNewColumn(data, "cardLadderComp", true);
      const b =  updateVisibilityWithNewColumn(a, "cardLadderLastSale", true);
      return updateVisibilityWithNewColumn(b, "cardLadderConfidence", true);
    },
  }
};

export const mutate = (itemVersion: string, itemString: string, value: any) => {
  
  const versionsToRun = Object.keys(mutationsDict)
    .filter((version) => compareVersions(version, itemVersion) > 0)
    .sort((a, b) => compareVersions(a, b));

  if (versionsToRun.length === 0) {
    return value;
  }

  versionsToRun.forEach((version) => {
    const versionMutations = mutationsDict[version];
    Object.keys(versionMutations).forEach((key) => {
      if (itemString === key) {
        value = versionMutations[key](value);
      }
    });
  });
  return value;
};
