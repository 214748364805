import { Button} from "@mui/material";
import { useState } from "react";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import useFetch from "../CommonUtility/useFetch";
import { ShowFileUploadStatuses } from "./ShowFileUploadStatuses";


export interface UploadTaskDTO {
    publicId: string,
    uploadedBy: string,
    timeOfUpload: Date
}

export const UploadTasksData = () => {
    const [selectedUploadTask, setSelectedUploadTask] = useState<UploadTaskDTO | null>(null)
    const {data: rows, loading} = useFetch("/MageAi/uploadTasks")
    
    if(loading) return <div>loading</div>

    function handleViewClick(row: UploadTaskDTO) {
        setSelectedUploadTask(row);
    }
    function handleClose() {
        setSelectedUploadTask(null)
    }

    return (
        <>
            {selectedUploadTask != null ? 
                <ShowFileUploadStatuses 
                    uploadTaskId={selectedUploadTask.publicId}
                    titleName={"Upload from " + selectedUploadTask.uploadedBy}
                    onClose={() => handleClose()}
                 /> :
                 <></>
            }

            <DataGridPremium
                columns={[
                    {field: "timeOfUpload",
                        headerName:"Time of Upload",
                        width: 300,
                        type: 'dateTime',
                        valueGetter: (params) => {
                            return new Date(params)
                        }
                    },
                        {field: "uploadedBy",
                            headerName:"Uploaded By",
                            width: 150,
                            type: 'string'
                        }, 
                        {
                        field: 'actions',
                        type: 'actions',
                        headerName: 'Actions',
                        width: 100,
                        cellClassName: 'actions',
                        filterable: false,
                        sortable: false,
                        getActions: ({ id, row }) => {
                            return [
                                <Button key={id} onClick={() => handleViewClick(row)} >View Uploads</Button>,
                            ];
                          },
                        }
                    ]}
                    rows={rows}
                />
        </>
    )
}