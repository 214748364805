import React, { useEffect } from "react";
import {
  Button,
  TextField,
  Grid,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useBuildRepacks } from "../../../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksContext";
import { useRepackTicketContext } from "../../../Contexts/RepackContexts/RepackTicketContexts/RepackTicketContext";
import { RepackTicketType } from "../Types/RepackTicketType";
import { RepackSeries } from "../Types/RepackSeries";
import RepackTicketView from "./RepackTicketView";

const RepackTicketManager: React.FC = () => {
  const { seriesList, ticketTypes } = useBuildRepacks();
  const {
    setCost,
    multipleTickets,
    numberOfTickets,
    ticketType,
    setTicketType,
    setTicketInputs,
    loading,
    prizes,
    setPrizes,
    series,
    setSeries,
    quantity,
    setQuantity,

    handleAddTickets,
  } = useRepackTicketContext();

  const computeAverageCost = () => {
    if (prizes.length === 0) return 0;
    const totalCost = prizes.reduce(
      (sum, prize) => sum + (Number(prize.cost) || 0),
      0
    );
    const temp = totalCost / prizes.length;
    setCost(temp);
    return temp;
  };


  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuantity = parseInt(e.target.value, 10);
    if (!isNaN(newQuantity) && newQuantity > 0) {
      setPrizes(
        Array(newQuantity)
          .fill(null)
          .map((_, index) => ({
            id: index,
            name: "",
            cost: 0,
            isClaimed: false,
          }))
      );
      setQuantity(newQuantity);
    } else {
      setPrizes([]);
    }
  };

  const handlePrizeChange = (index: number, value: string) => {
    setPrizes((currentPrizes) =>
      currentPrizes.map((prize, i) =>
        i === index ? { ...prize, name: value } : prize
      )
    );
  };

  const handlePrizeCostChange = (index: number, value: string) => {
    const cost = parseInt(value, 10);
    setPrizes((currentPrizes) =>
      currentPrizes.map((prize, i) =>
        i === index ? { ...prize, cost: isNaN(cost) ? 0 : cost } : prize
      )
    );
  };

  useEffect(() => {
    if (multipleTickets && numberOfTickets) {
      const count = parseInt(numberOfTickets as string, 10);
      const inputs = Array.from({ length: count }).map(() => ({
        name: "",
        cost: "",
      }));
      setTicketInputs(inputs);
    }
  }, [multipleTickets, numberOfTickets, setTicketInputs]);

  return (
    <Box
      sx={{
        height: "calc(100vh - 32px)",
        m: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      {/* Create Tickets Accordion - Fixed height */}
      <Accordion
        defaultExpanded
        sx={{
          maxHeight: "45vh",
          overflow: "auto",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Create Repack Tickets
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {/* First Row: Ticket Type, Cost, Quantity */}
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Ticket Type</InputLabel>
                  <Select
                    value={ticketType}
                    onChange={(e) =>
                      setTicketType(e.target.value as RepackTicketType)
                    }
                    label="Ticket Type"
                  >
                    {ticketTypes && ticketTypes.length > 0 ? (
                      ticketTypes.map((type: any) => (
                        <MenuItem key={type.id} value={type}>
                          {type.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No Ticket Types Available</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Ticket Cost"
                  type="number"
                  value={computeAverageCost()}
                  disabled
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Quantity"
                  type="number"
                  value={quantity}
                  onChange={handleQuantityChange}
                  variant="outlined"
                />
              </Grid>
            </Grid>

            {/* Prize Inputs */}
            <Grid item xs={12}>
              <div
                style={{
                  maxHeight: "15rem",
                  overflowY: "auto",
                  padding: "1rem 0",
                }}
              >
                <Grid container spacing={2}>
                  {prizes.map((prize, index) => (
                    <React.Fragment key={index}>
                      <Grid item xs={12} md={8}>
                        <TextField
                          fullWidth
                          label={`Prize ${index + 1}`}
                          value={prize.name}
                          onChange={(e) =>
                            handlePrizeChange(index, e.target.value)
                          }
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={8} md={4}>
                        <TextField
                          fullWidth
                          label={`Prize Cost ${index + 1}`}
                          type="number"
                          value={prize.cost || ""}
                          onChange={(e) =>
                            handlePrizeCostChange(index, e.target.value)
                          }
                          variant="outlined"
                        />
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
              </div>
            </Grid>

            {/* Series Selection */}
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Series</InputLabel>
                <Select
                  label="Series"
                  value={series}
                  onChange={(e) => setSeries(e.target.value as RepackSeries)}
                >
                  {seriesList ? (
                    seriesList.map((seriesItem: any) => (
                      <MenuItem key={seriesItem.id} value={seriesItem}>
                        {seriesItem.productId}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No Series Available</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>

            {/* Submit Button */}
            <Grid item xs={12} md={6}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddTickets}
                disabled={loading}
                startIcon={loading ? <CircularProgress size={24} /> : null}
              >
                {loading ? "Adding..." : "Add Tickets"}
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      {/* View Tickets Accordion - Takes remaining space */}
      <Accordion
        defaultExpanded
        sx={{
          flex: "1 1 45vh",
          display: "flex",
          flexDirection: "column",
          minHeight: "45vh",
          maxHeight: "45vh",
          "& .MuiAccordion-root": {
            display: "flex",
            flexDirection: "column",
          },
          "& .MuiCollapse-root": {
            flex: 1,
          },
          "& .MuiCollapse-wrapper": {
            height: "100%",
          },
          "& .MuiCollapse-wrapperInner": {
            height: "100%",
            display: "flex",
            flexDirection: "column",
          },
          "& .MuiAccordionDetails-root": {
            padding: 1,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          },
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          View Repack Tickets
        </AccordionSummary>
        <AccordionDetails>
          <RepackTicketView />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default RepackTicketManager;
