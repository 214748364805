import * as signalR from '@microsoft/signalr';
import useFetch from "../../Components/CommonUtility/useFetch";
import { useEffect, useMemo, useState } from "react";

import api from "../../Modules/APIModules";
import { Change, DataSourceUpdateList } from './DataSourceUpdateList';
import FetchModule from '../../Modules/FetchModule';
import { HttpTransportType } from '@microsoft/signalr';
import { Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/material';

export interface LiveUpdateDataSourceProps<T> {
  baseUrl: string;
  hubUrl: string;
  defaultData?: T[];
  groups?: string[];
}
const apiUrl = api.getLink()
const fetchModule = new FetchModule();

export const useLiveUpdateDataSource = <T extends {id: string | number, publicId: string}>({
  baseUrl, 
  hubUrl, 
  defaultData,
  groups
}: LiveUpdateDataSourceProps<T>) => {
  const { data, error, loading } = useFetch(baseUrl, true, defaultData || []);
  const [isConnected, setIsConnected] = useState(false);
  const [localData, setLocalData] = useState<T[]>(defaultData || []);

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(localData)) {
      setLocalData(data);
    }
  }, [data]);

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(apiUrl+hubUrl, {
        accessTokenFactory: () => {
          const token = fetchModule.getCookie("jwtToken");
          if (!token) {
            throw new Error("No token found");
          }
          return token;
        },
        transport: HttpTransportType.WebSockets | HttpTransportType.LongPolling | HttpTransportType.ServerSentEvents
      })
      .withAutomaticReconnect([0, 2000, 5000, 10000, 30000])
      .build();

    const startConnection = async () => {
      try {
        await connection.start();
        console.info("SignalR Connected");
        if(groups){
          await connection.invoke("Register", groups);
        }
        setIsConnected(true);
      } catch (err) {
        console.error("SignalR Connection Error: ", err);
        setTimeout(startConnection, 5000);
      }
    };

    startConnection();

    connection.onreconnecting((error) => {
      console.warn("SignalR Reconnecting:", error);
    });

    connection.onreconnected((connectionId) => {
      console.info("SignalR Reconnected:", connectionId);
    });

    connection.onclose((error) => {
      console.error("SignalR Connection Closed:", error);
    });

    connection.on("Add", (dto: T) => {
      setLocalData((currentData: T[]) => {
        const rowIndex = currentData.findIndex((row: T) => row.id === dto.id);
        if (rowIndex === -1) {
          return [...currentData, dto];
        }
        const newRows = [...currentData];
        newRows[rowIndex] = dto;
        return newRows;
      });
    });

    connection.on("Update", (changes: DataSourceUpdateList<T>[]) => {
      setLocalData((currentData: T[]) => {
        const newData = [...currentData];
        
        changes.forEach((change: DataSourceUpdateList<T>) => {
          const rowIndex = newData.findIndex((row: T) => row.id === change.entityId);
          if (rowIndex !== -1) {
            const updatedRow = {...newData[rowIndex]};
            change.changes.forEach((fieldChange: Change<T>) => {
              updatedRow[fieldChange.field] = fieldChange.newValue;
            });
            newData[rowIndex] = updatedRow;
          }
        });

        return newData;
      });
    });

    connection.on("Replace", (dto: T) => {
      setLocalData((currentData: T[]) => {
        const rowIndex = currentData.findIndex((row: T) => row.id === dto.id);
        if (rowIndex === -1) {
          return [...currentData, dto];
        }
        const newRows = [...currentData];
        newRows[rowIndex] = dto;
        return newRows;
      });
    });

    
    connection.on("Delete", (publicId: string) => {

      setLocalData((currentData: T[]) => {
        return currentData.filter((row: T) => row.publicId !== publicId);
      });
    });


    return () => {
      console.info("Stopping connection in the return of that use effect");
      connection.stop().catch(err => console.error("Error stopping connection:", err));
    };

  }, []);

  const LoadingDisplay = useMemo(() => {
    
    const isLoading = !isConnected || loading
    return (
      <Tooltip title={isLoading ? 
        "Disconnected and not receiving live updates" : 
        "Connected and receiving live updates"
        }
        placement="top"
        TransitionProps={{ style: { marginBottom: '-8px' } }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          
            <Box
              sx={{
                width: 10,
                height: 10,
                borderRadius: '50%',
                backgroundColor: isLoading ? '#ff4444' : '#44ff44',
              }}
            />
            <Typography 
            variant="body2" 
            color={isLoading ? 'error' : 'success'}
            >
              {isLoading ? 'Disconnected' : 'Connected'}
            </Typography>
        

        </Box>
        
        </Tooltip>
    )
  }, [isConnected, loading]);

  return { 
    data: localData, 
    setData: setLocalData, 
    error, 
    loading: !isConnected || loading,
    LoadingDisplay
  };
}