import React, { useCallback } from "react";
import "../../Hamburger.css";
import {
  GridRowModes,
  GridActionsCellItem,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  getGridNumericOperators,
} from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import Tooltip from "@mui/material/Tooltip";
import { Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import LowPriorityIcon from "@mui/icons-material/LowPriority";
import HistoryIcon from '@mui/icons-material/History';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import "../singles.css";

//custom toolbar and pagination
import CustomToolbar from "../ToolbarComponents/CardsToolbar";
import CustomPagination from "../FooterComponents/CardPagination";

import FetchModule from "../../../Modules/FetchModule";
import { useState, useEffect } from "react";

import HistorianModal from "../../../Components/HistorianModal/HistorianModal";
import { useCardDashboard } from "../CardDashboardContext";
import { useCards } from "../../../Contexts/CardsContext/CardsContext";
import { NewSkuFilter } from "../FilterComponents/NewSkuFilter";
import { GenericStringFilter } from "../FilterComponents/GenericStringFilter";
import { GradeFilter } from "../FilterComponents/GradeFilter";
import { CompInput } from "./CompInput";
import { DateFilter } from "../FilterComponents/DateFilter";
import { CardLadderHistoryTooltip } from "../CardLadder/CardLadderHistoryTooltip";
import { CardLadderConfidence } from "../CardLadder/CardLadderConfidence";

function DataGridComponent({
  rows,
  handlePageChange,
  handlePageSizeChange,
  changeColumnWidth,
  columnFilterDisplayModel,
  handleRowEditStart,
  handleRowEditStop,
  handleRowModesModelChange,
  processRowUpdate,
  handleProcessRowUpdateError,
  selectionModel,
  setSelectedRows,
  setSelectionModel,
  selectedRows,
  onRowSelectionModelChange,
  rowModesModel,
  setColumnVisibility,
  refresh,
  setRefresh,
  snackbar,
  setSnackbar,
  columnWidths,
  colOrder,
  setColOrder,
  handleSaveClick,
  handleMoveGradeClick,
  handleMoveRawClick,
  handleCancelClick,
  handleEditClick,
  renderAutoCompleteCellPlayer,
  renderMultiSelectAutoCompleteCell,
  renderACDisplayCell,
  renderAutocompleteCellSkuItem,
  renderAttributesSelectCell,
  renderAttributesSelectDisplayCell,
  renderSelectCell,
  renderSelectDisplayCell,
  renderCellExpand,
  renderCustomTextInput,
  renderCustomDate,
  renderAutoCompleteUser,
  renderUser,
  renderCert,
  setJumpPage,
  renderCustomNumberInput,
  renderAutocompleteVendor,
  MoveCardConfirmationModal,
  changeStatusOnLiveCells,
  renderMultiselectACDisplayCell,
  getRowHeight,
  renderAutocompleteCellSetVariety,
  renderAutocompleteCellSkuItemPropogateChecklistSearch,
  renderDateTime
}) {
  /*
        Columns correspond to the relationship of the SingleCards table
        - field is the same name as the column within the table column name
        - type is the data type of that value
        - then the width corresponds to columnWidths which is a relationship
          within local stored columnWidths that uses the field as a dictionary of the field
           (see columnFilterDisplayModel)
    */

  const fetchData = new FetchModule();

  const {vendors,statusTypes,users,players,skuList,teams,cachedChecklistResults} = useCardDashboard();
  const {totalRows, paginationModel, filterModel, updateFilterModel, setFilterModel, sortModel, setSortModel, goTo, setPageSize, setCurrentPage,gridApi} = useCards();

  //custom autocomplete filtering
  const attributes = [
    { value: "RC", label: "RC" },
    { value: "Auto", label: "Auto" },
    { value: "Auto RC", label: "Auto RC" },
    { value: "1st", label: "1st" },
    { value: "1st Auto", label: "1st Auto" }
  ];  

  const AttributeOperators = GenericStringFilter({
    options: attributes,
    field: "attributes",
    label: "label",
    updateFilterModel: updateFilterModel,
    filterModel: filterModel
  })

  const VendorOperators = GenericStringFilter({
    options:vendors,
    field: "vendor",
    updateFilterModel: updateFilterModel,
    filterModel: filterModel
  })

  const StatusOperators = GenericStringFilter({
    options: statusTypes,
    field: "status",
    label: "label",
    updateFilterModel: updateFilterModel,
    filterModel: filterModel
  })


  const PurchasedByOperators = GenericStringFilter({
    options: users,
    field: "purchasedBy",
    updateFilterModel: updateFilterModel,
    filterModel: filterModel
  })

  const PlayerOperators = GenericStringFilter({
    options: players,
    field: "player",
    label: "label",
    updateFilterModel: updateFilterModel,
    filterModel: filterModel
  })

  const TeamOperators = GenericStringFilter({
    options: teams,
    field: "team",
    label: "name",
    updateFilterModel: updateFilterModel,
    filterModel: filterModel
  })

  const GradeOperators = GradeFilter({
    options:skuList.Grade.items,
    field: "grade",
    label: "label",
    updateFilterModel: updateFilterModel,
    filterModel: filterModel
  })

  const YearOperators = NewSkuFilter({
    options:skuList.Year.items,
    field: "year",
    updateFilterModel: updateFilterModel,
    filterModel: filterModel
  });

  const ManufacturerOperators = NewSkuFilter({
    options:skuList.Manufacturer.items,
    field: "manufacturer",
    updateFilterModel: updateFilterModel,
    filterModel: filterModel
  })

  const SeriesOperators = NewSkuFilter({
    options:skuList.Series.items,
    field: "series",
    updateFilterModel: updateFilterModel,
    filterModel: filterModel
  })

  const LeagueOperators = NewSkuFilter({
    options:skuList.League.items,
    field: "league",
    updateFilterModel: updateFilterModel,
    filterModel: filterModel
  })

  const SetOperators = NewSkuFilter({
    options:skuList.Set.items,
    field: "setVariety",
    updateFilterModel: updateFilterModel,
    filterModel: filterModel
  })

  const DateOperators = DateFilter()
 
  const [historianModalOpen, setHistorianModalOpen] = useState(false);
  const [historianModalData, setHistorianModalData] = useState([]);

  const handleHistorianModalOpen = async (publicId) => {
    const url = `/card/history/card/${publicId}`;
    const response = await fetchData.fetchResponseAuthed(url, "GET");
    const data = await response.json();
    setHistorianModalData(data);
    setHistorianModalOpen(true);
  };

  const handleHistorianModalClose = () => {
    setHistorianModalOpen(false);
    setHistorianModalData([]);
  };

  const findSimilarTo = (row) => {
    const year = row.year?.label;
    const player = row.player?.label;
    const series = row.series?.label;
    const setVariety = row.setVariety?.label;
    setFilterModel({
      items: [
        {field:"year",operator:"equals",value:year},
        {field:"player",operator:"equals",value:player},
        {field:"series",operator:"equals",value:series},
        {field:"setVariety",operator:"equals",value:setVariety},
      ],
      logicOperator:"and",
      quickFilterValues: []

    })
  }

  const inventoryIdOperators = useCallback(getGridNumericOperators().map((operator) => {
    if (operator.value === "isAnyOf") {
      return {
        ...operator,
        InputComponentProps: {
          onPaste:(e=>{
            e.preventDefault();
            const data = e.clipboardData.getData('Text')
            const cleanedData = data.replace(/[ \t\r,]+/g, '').split('\n');
            if(filterModel.items.some(e=>e.field === "inventoryId" && e.operator === "isAnyOf")){
              const oldData = filterModel.items.find(e=>e.field === "inventoryId" && e.operator === "isAnyOf").value
              const mergedData = Array.isArray(oldData) ? [...new Set([...oldData, ...cleanedData])] : cleanedData;
              setFilterModel(
                {
                  ...filterModel,
                  items: filterModel.items.map(e=>{
                    if(e.field === "inventoryId" && e.operator === "isAnyOf"){
                      return {...e,value:mergedData}
                    }
                    return e
                  })
                }
              )
             }
          })
        },
      };
    }
    return operator;
  }),[filterModel,gridApi])

  const dateFormatter = (params) => {
    if (!params.value) return '';
    return params.value.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      timeZone: 'UTC'
    });
}

  const calcCols = () => {
    var cols = [
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        width: columnWidths["actions"],
        cellClassName: "actions",
        renderCell: (params) => {
          var id = params.id;
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
  
          var retList = [
            <GridActionsCellItem
              key={"carddatagrid-saveicon"+id}
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
          ];
  
          if (params.row?.inventoryId >= 10000000) {
            retList.push(
              <Tooltip title="Move to graded">
                <GridActionsCellItem
                  key={"carddatagrid-moveToGraded"+id}
                  icon={<MoveUpIcon />}
                  label="Move to Graded"
                  onClick={handleMoveGradeClick(id)}
                />
              </Tooltip>
            );
          }
          if (params.row?.inventoryId >= 20000000) {
            retList.push(
              <Tooltip title="Move to raw">
                <GridActionsCellItem
                  key={"carddatagrid-moveToRaw"+id}
                  icon={<LowPriorityIcon />}
                  label="Move to raw"
                  onClick={handleMoveRawClick(id)}
                />
              </Tooltip>
            );
          }
          retList.push(
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />
          );
  
          if (isInEditMode) {
            return retList;
          }
          else {
            return [
              <GridActionsCellItem
                key={"carddatagrid-editicon"+id}
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                onClick={handleEditClick(id)}
                color="inherit"
              />,
              <GridActionsCellItem
                key={"carddatagrid-moveToAuditIcon"+id}
                icon={<HistoryIcon />}
                label="Audit"
                onClick={()=>{
                  handleHistorianModalOpen(params.row.publicId)
                }}
                color="inherit"
              />,
              <GridActionsCellItem
                key={"carddatagrid-troubleshootIcon"+id}
                icon={<TroubleshootIcon />}
                label="Find Similar"
                onClick={()=>{
                  findSimilarTo(params.row)
                }}
                color="inherit"
              />,
            ];
          }
        },
      },
      {
        field: "inventoryId",
        headerName: "Inventory Id",
        type: "number",
        width: columnWidths["inventoryId"],
        editable: false,
        filterOperators:inventoryIdOperators,
      },
      {
        field: "purchase",
        headerName: "Purchased",
        type: "date",
        width: columnWidths["purchase"],
        editable: true,
        renderEditCell: renderCustomDate,
        valueGetter: (params) => (params.value ? new Date(params.value) : null),
        valueFormatter: dateFormatter,
        filterOperators: DateOperators,
        
      },
      {
        field: "received",
        headerName: "Received",
        type: "date",
        width: columnWidths["received"],
        editable: true,
        renderEditCell: renderCustomDate,
        valueGetter: (params) => (params.value ? new Date(params.value) : null),
        valueFormatter: dateFormatter,
        filterOperators: DateOperators,
      },
      {
        field: "player",
        headerName: "Player",
        width: columnWidths["player"],
        editable: true,
        filterOperators: PlayerOperators,
        renderEditCell: renderAutoCompleteCellPlayer,
        renderCell: renderACDisplayCell,
        type: "text",
      },
      {
        field: "year",
        headerName: "Year",
        width: columnWidths["year"],
        editable: true,
        skuType: "Year",
        filterOperators: YearOperators,
        renderEditCell: renderAutocompleteCellSkuItemPropogateChecklistSearch,
        renderCell: renderACDisplayCell,
        type: "text",
      },
      {
        field: "manufacturer",
        headerName: "Manufacturer",
        width: columnWidths["manufacturer"],
        editable: true,
        skuType: "Manufacturer",
        filterOperators: ManufacturerOperators,
        renderEditCell: renderAutocompleteCellSkuItemPropogateChecklistSearch,
        renderCell: renderACDisplayCell,
        type: "text",
      },
      {
        field: "series",
        headerName: "Series",
        width: columnWidths["series"],
        type: "text",
        skuType: "Series",
        editable: true,
        filterOperators: SeriesOperators,
        renderEditCell: renderAutocompleteCellSkuItemPropogateChecklistSearch,
        renderCell: renderACDisplayCell,
      },
      {
        field: "setVariety",
        headerName: "Set/Variety",
        editable: true,
        skuType: "Set",
        width: columnWidths["setVariety"],
        type: "text",
        filterOperators: SetOperators,
        renderEditCell:(params) =>{
          return renderAutocompleteCellSetVariety(params,gridApi)
        },
        renderCell: renderACDisplayCell,
      },
      {
        field: "attributes",
        headerName: "Attributes",
        type: "text",
        width: columnWidths["attributes"],
        editable: true,
        filterOperators: AttributeOperators,
        renderEditCell: renderAttributesSelectCell,
        renderCell: renderAttributesSelectDisplayCell,
      },
      {
        field: "serial",
        headerName: "Serial",
        type: "text",
        width: columnWidths["serial"],
        editable: true,
        renderEditCell: renderCustomTextInput,
      },
      {
        field: "grade",
        headerName: "Grade",
        width: columnWidths["grade"],
        skuType: "Grade",
        editable: true,
        type: "text",
        filterOperators: GradeOperators,
        renderEditCell: renderAutocompleteCellSkuItem,
        renderCell: renderACDisplayCell,
      },
      {
        field: "gradeSent",
        headerName: "Grading Sent",
        type: "date",
        width: columnWidths["gradeSent"],
        editable: true,
        renderEditCell: renderCustomDate,
        valueGetter: (params) => (params.value ? new Date(params.value) : null),
        valueFormatter: dateFormatter,
        filterOperators: DateOperators,
      },
      {
        field: "gradeRec",
        headerName: "Grading Rec",
        type: "date",
        width: columnWidths["gradeRec"],
        editable: true,
        renderEditCell: renderCustomDate,
        valueGetter: (params) => (params.value ? new Date(params.value) : null),
        valueFormatter: dateFormatter,
        filterOperators: DateOperators,
      },
      {
        field: "certNumber",
        headerName: "Cert #",
        type: "string",
        width: columnWidths["certNumber"],
        editable: true,
        renderEditCell: renderCustomTextInput,
        renderCell:renderCert
      },
      {
        field: "submissionId",
        headerName: "PSA Submission ID",
        type: "string", 
        width: columnWidths["submissionId"] || 120,
        editable: true,
      },
      {
        field: "cardNumber",
        headerName: "Card #",
        type: "text",
        width: columnWidths["cardNumber"],
        editable: true,
        renderEditCell: renderCustomTextInput,
      },
      {
        field: "league",
        headerName: "League",
        width: columnWidths["league"],
        editable: true,
        skuType: "League",
        type: "text",
        filterOperators: LeagueOperators,
        renderEditCell: renderAutocompleteCellSkuItem,
        renderCell: renderACDisplayCell,
      },
      {
        field: "cost",
        headerName: "Cost",
        width: columnWidths["cost"],
        editable: true,
        type: "number",
        renderEditCell: renderCustomNumberInput,
      },
      {
        field: "fees",
        headerName: "Fees",
        width: columnWidths["fees"],
        editable: true,
        type: "number",
        renderEditCell: renderCustomNumberInput,
      },
      {
        field: "gradingFees",
        headerName: "Grading Fees",
        width: columnWidths["gradingFees"],
        editable: true,
        type: "number",
        renderEditCell: renderCustomNumberInput,
      },
      {
        field: "totalCost",
        headerName: "Total Cost",
        width: columnWidths["totalCost"],
        editable: false,
        valueGetter: (params) =>
          params.row.cost + params.row.gradingFees + params.row.fees,
        type: "number",
      },
      {
        field: "comp",
        headerName: "Comp",
        width: columnWidths["comp"],
        type: "number",
        editable: true,
        renderEditCell: CompInput,
      },
      {
        field: "compLastModified",
        headerName: "Comp Last Modified",
        width: columnWidths["compLastModified"],
        editable: false,
        type: "date",
        valueGetter: (params) => {
          return params.row.compLastModified ? new Date(params.row.compLastModified) : null},
        valueFormatter: dateFormatter,
        renderCell: renderDateTime,
        filterOperators: DateOperators,
      },
      
      {
        field: "cardLadderComp",
        headerName: "CL Value",
        width: columnWidths["cardLadderComp"],
        type: "number",
        editable: false,
        valueGetter: (params) => {
          return params.row.cardLadderCertData?.estimatedValue
        },
        renderCell: CardLadderHistoryTooltip
      },
      {
        field: "cardLadderLastSale",
        headerName: "CL Last Sale",
        width: columnWidths["cardLadderLastSale"],
        type: "date",
        editable: false,
        valueGetter: (params) => {
          return params.row.cardLadderCertData?.lastSaleDate ? new Date(params.row.cardLadderCertData?.lastSaleDate) : null}
      },
      {
        field: "cardLadderConfidence",
        headerName: "Confidence",
        width: columnWidths["cardLadderConfidence"],
        type: "number",
        editable: false,
        valueGetter: (params) => {
          return params.row.cardLadderCertData?.confidence
        },
        renderCell: CardLadderConfidence,
      },
      {
        field: "vendor",
        headerName: "Vendor",
        width: columnWidths["vendor"],
        editable: true,
        type: "text",
        renderEditCell: renderAutocompleteVendor,
        filterOperators: VendorOperators,
      },
      {
        field: "teams",
        headerName: "Team",
        width: columnWidths["teams"],
        editable: true,
        filterOperators: TeamOperators,
        renderEditCell: (params) => {
          return renderMultiSelectAutoCompleteCell(params,gridApi);
        },
        renderCell: renderMultiselectACDisplayCell,
        type: "text",
      },
      {
        field: "description",
        headerName: "Description",
        width: columnWidths["description"],
        editable: true,
        type: "text",
        renderEditCell: renderCustomTextInput,
      },
      {
        field: "purchasedBy",
        headerName: "Purchase By",
        width: columnWidths["purchsedBy"],
        editable: true,
        type: "text",
        filterOperators: PurchasedByOperators,
        renderEditCell: renderAutoCompleteUser,
        renderCell:renderUser,
      },
      {
        field: "initials",
        headerName: "Initials",
        width: columnWidths["initials"],
        type: "text",
      },
      {
        field: "status",
        headerName: "Status",
        width: columnWidths["status"],
        editable: true,
        type: "string",
        filterOperators: StatusOperators,
        renderEditCell: renderSelectCell,
        renderCell: renderSelectDisplayCell,
      },
      {
        field: "flagged",
        headerName: "Flagged",
        width: columnWidths["flagged"],
        type: "boolean",
        editable: true,
      },
      {
        field: "isDear",
        headerName: "In Dear",
        width: columnWidths["isDear"],
        type: "boolean",
        editable: true,
      },
      {
        field: "sku",
        headerNmae: "SKU",
        width: columnWidths["sku"],
        type: "text",
      },
      {
        field: "name",
        headerName: "Name",
        width: columnWidths["name"],
        type: "text",
        editable: false,
        renderCell: renderCellExpand,
      },
    ]
    const sortedCols = cols.sort((a, b) => {
      return colOrder.indexOf(a.field) - colOrder.indexOf(b.field);
    });
    return sortedCols
  }

  const columns = React.useMemo(calcCols,[colOrder,skuList,rowModesModel,filterModel,players,teams,cachedChecklistResults]);


  const handleOrderChange = (params)=>{
    const newIndex  =  params.targetIndex-1
    const oldIndex = params.oldIndex-1
    const shiftOrders = (array, fromIndex, toIndex) => {
      // Remove the element from the array at the fromIndex
      const element = array.splice(fromIndex, 1)[0];
      // Insert the removed element into the array at the toIndex
      array.splice(toIndex, 0, element);
      setColOrder([...array])
    }
    shiftOrders(colOrder,oldIndex,newIndex)

  }

  const [isScrolling, setIsScrolling] = React.useState(false);

  const MemoizedToolbar = React.useCallback(() => {
    return (<CustomToolbar
              refresh={refresh}
              setRefresh={setRefresh}
              snackbar={snackbar}
              setSnackbar={setSnackbar}
              selectedRows={selectedRows}
              colOrder={colOrder}
              colVis = {columnFilterDisplayModel}
              changeStatusOnLiveCells={changeStatusOnLiveCells}
              filterModel={filterModel}
              setFilterModel={setFilterModel}
            />)
  },[colOrder,selectedRows,filterModel,snackbar,refresh,columnFilterDisplayModel])

  const MemoizedPagination = React.useCallback(() => {
    return(<CustomPagination
      refresh={refresh}
      setJumpPage={setJumpPage}
      totalRows={totalRows}
      goTo={goTo}
      paginationModel={paginationModel}
      setPageSize={setPageSize}
      setCurrentPage={setCurrentPage}
      selectedRows={selectedRows}
    />)
  },[refresh,totalRows,paginationModel,selectedRows])

  const gridRef = React.useRef();
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolling(true);
      clearTimeout(handleScroll.timeout);
      handleScroll.timeout = setTimeout(() => {
        setIsScrolling(false);
      }, 250);
    };

    const observer = new MutationObserver(() => {
      const gridElement = gridRef.current?.querySelector('.MuiDataGrid-virtualScroller');
      if (gridElement) {
        gridElement.addEventListener('scroll', handleScroll);
        observer.disconnect(); // Stop observing once the element is found and event is bound
      }
    });

    observer.observe(gridRef.current, {
      childList: true,
      subtree: true,
    });

    return () => {
      const gridElement = gridRef.current?.querySelector('.MuiDataGrid-virtualScroller');
      if (gridElement) {
        gridElement.removeEventListener('scroll', handleScroll);
      }
      observer.disconnect();
    };
  }, []);

  return (
    <Box
      className="data-grid"
      sx={{
        height: "90%",
        overflow: "hidden",
        fontSize: "0.75rem",
        "& .MuiDataGrid-cell:hover": {
          color: "primary.main",
        },
      }}
      ref={gridRef}
    >
      <MoveCardConfirmationModal/>
      <HistorianModal
        open={historianModalOpen}
        onClose={handleHistorianModalClose}
        data={historianModalData}
        name="History Records"
      />
      <DataGridPro
        apiRef={gridApi}
        density="compact"
        page={paginationModel.page}
        pagination={true}
        paginationMode="server"
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        pageSize={paginationModel.pageSize}
        rowCount={totalRows}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
        }
        getRowHeight={getRowHeight}
        componentsProps={{
          pagination: {
            extendrowsize: "true",
            count: totalRows,
          },
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
          filterPanel: {
            //somehow this works for making filter panel auto handle width?
            sx: { 
              '& .MuiDataGrid-filterForm': { 
                width: 'max-content',
                minWidth: '280px',  
                maxWidth: '600px'   
              },
              '& .MuiDataGrid-filterFormOperatorInput': {
                width: 'max-content',
                minWidth: '120px'   
              }
            }
          }
        }}
        slots={{
          pagination: MemoizedPagination,
          toolbar: MemoizedToolbar
        }}
        rows={rows}
        columns={columns}
        editMode="row"
        onRowModesModelChange={handleRowModesModelChange}
        //disableSelectionOnClick
        rowModesModel={rowModesModel}
        onRowEditStart={(params)=>{
          handleRowEditStart(params)
        }}
        onRowEditStop={(params,event)=>{
          handleRowEditStop(params,event,isScrolling)}
        }
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        //selection shit
        checkboxSelection
        setSelectionModel={setSelectionModel}
        onRowSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const selectedRows = rows.filter((row) => selectedIDs.has(row.id));
          setSelectedRows(selectedRows);
          setSelectionModel(ids);
          onRowSelectionModelChange(ids);
        }}
        rowSelectionModel={selectionModel}
        filterMode="server"
        //end selection
        onFilterModelChange={
          (newFilterModel)=>{
            setFilterModel(newFilterModel)
          }
        }
        filterModel={filterModel}
        onColumnWidthChange={changeColumnWidth}
        columnVisibilityModel={columnFilterDisplayModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibility(newModel)
        }
        onColumnOrderChange={handleOrderChange}
        initialState={{
          pinnedColumns: {
            left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, "actions"],
          },
        }}
        // Serverside sorting stuff
        sortingMode="server"
        onSortModelChange={(newSortModel)=>{
          setSortModel(newSortModel)
        }}
        sortModel={sortModel}
      />
    </Box>
  );
}

export default DataGridComponent;
