import { OperatorSetBuilder } from "../../../Components/CommonUtility/ServerSide/FilterComponents/OperatorsBuilder";

export const DateFilter = () => {
  const operatorBuilder = new OperatorSetBuilder();
  operatorBuilder.addDateSelection("is", "is");
  operatorBuilder.addDateSelection("is not", "isNot");
  operatorBuilder.addDateSelection("is after", "after")
  operatorBuilder.addDateSelection("is on or after", "onorafter");
  operatorBuilder.addDateSelection("is before", "before");
  operatorBuilder.addDateSelection("is on or before", "onorbefore");
  operatorBuilder.addNoValueFilter("is empty", "isEmpty");
  operatorBuilder.addNoValueFilter("is not empty", "isNotEmpty");
  operatorBuilder.addBasicOperator("in past X days", "lastXDays");
  operatorBuilder.addBasicOperator("in past X weeks", "lastXWeeks");
  operatorBuilder.addBasicOperator("in past X months", "lastXMonths");
  operatorBuilder.addBasicOperator("in past X years", "lastXYears");
  return operatorBuilder.build();
}