import { Avatar, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useLiveUpdateDataSource } from "../../Utilities/LiveUpdateDataSource/LiveDatasource"
import { useState } from "react";
import { DataGridPremium } from "@mui/x-data-grid-premium";


export interface ShowFileUploadStatus {
    id: number,
    publicId: string,
    status: string,
    fileName:string,
    uploadTaskPublicId: string,
}

interface ShowFileUploadStatusesProps {
    uploadTaskId: string;
    titleName?: string;
    onClose?: () => void
}
const chipTypes = {
    "initial": <Chip variant="outlined" label="Initializing" />,
    "Running": <Chip variant="outlined" color="info" avatar={<Avatar><CircularProgress/></Avatar>} label="Running..."/>,
    "Success": <Chip variant="outlined" label="Success" color="success" />
}

export const ShowFileUploadStatuses = ({uploadTaskId, titleName, onClose}: ShowFileUploadStatusesProps) => {
    const [open, setOpen] = useState(true)
    
    const {
        data: rows,
    } = useLiveUpdateDataSource<ShowFileUploadStatus>(
        {
            baseUrl: "/MageAi/upload/"+uploadTaskId,
            hubUrl: "/hubs/showFileUploads",
            defaultData: [],
            groups:[uploadTaskId]
        }
    );
    function handleClose() {
        if (onClose != undefined) onClose()
        setOpen(false)
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
                {titleName}
            </DialogTitle>
            <DialogContent>
                <DataGridPremium
                    columns={[
                        {field: "fileName",
                            headerName:"File Name",
                            width: 200,
                            type: 'string'
                        },
                        {
                            field: "status",
                            headerName:"File Upload Status",
                            width: 140,
                            type: 'string',
                            renderCell: (params) => {
                                const value = params.value
                                return chipTypes[value as keyof typeof chipTypes]
                            }
                        }
                    ]}
                    rows={rows}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}