import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {useState,useEffect} from 'react';
import { DashboardSidebarContext } from './ReactContexts/NavigateContext';
import "react-toastify/dist/ReactToastify.css";
import PackingPage from './PackingPage'
import { Navigate,useNavigate} from "react-router-dom";
import ErrorPage from './ErrorPage';
import PriceManagerPage from './PriceManagerPage';
import LoginPage from './LoginPage';
import { PantheonPage } from './PantheonPage';
import Session from '../Dash/BreakingPage/Session';
import BreakingPage from '../Dash/BreakingPage/BreakingPage';
import UploaderFirstPage from '../Dash/UploadPage/UploaderFirstPage';
import UserPage from '../Dash/UserMangement/UserPage';
import DashboardLayout from '../Components/DashboardComponents/DashboardLayout';
import Homepage  from './homepage.js'
import SettingsPage from './SettingsPage';
import CardsPage from './CardsPage';
import { useUser } from '../Components/CommonUtility/UserContext';
import ViewRepackPage from './ViewRepackPage';
import RepackLandingPage from './RepackLandingPage';
import RepackCreation from '../Dash/RepackPage/RepackCreation/RepackCreation';
import TicketPage from './TicketPage';

import { useLocation } from 'react-router-dom';
import BarcodePage from '../Dash/BarcodePage/BarcodeDisplay';
import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import { DialogContent } from '@material-ui/core';
import { Button } from '@mui/base';

import SkuCorrection from '../Dash/SkuCorrection/SkuCorrection';
import ShowHostsPage from './ShowHostsPage';
import {useFeatureFlags } from '../Contexts/FeatureFlagContexts/FeatureFlagContext';
import ShowPage from './ShowPage';
import PayPeriodPage from './PayPeriodPage';
import SinglePayPeriodPage from './SinglePayPeriodPage';
import { useLocalStorage, verifyLocalStorage } from '../Utilities/LocalStorage/useLocalStorage';

import { PermissionRoles } from '../Components/CommonUtility/PermissionFlagsEnum';
import { BulkCardCreatorPage } from './BulkCardCreatorPage';
import { LocalStorageKey } from '../Utilities/LocalStorage/LocalStorageEnum';
import ProductPage from '../CustomerPage/CustomerPageComponents/CustomerPage';
import TickerParent from '../Dash/Ticker/TickerParent';
import StreamComputerDashboard from '../Dash/StreamPcAssignment/StreamComputerDashboard';
import CardSyncCorrectionPage from './CardSyncCorrectionPage';

/*
    This Component handles all the routing for the application.
    So any new page handle like product.wethehobby.com/whatever
    will have to be handled here

*/



const TrackedRouter = (props)=>{
    const openSignoutWarning = props.openSignoutWarning;
    const setMessage = props.setMessage;
    const location = useLocation();
    const {user, isLoggedIn,invalidationReason,getExpirationDate, hasPermission } = useUser();
    const {flags, updateFlags} = useFeatureFlags();
    const navigate = useNavigate();

    useEffect(() => {
        verifyLocalStorage();
    },[])


    useEffect(() => {

        if(isLoggedIn()){
            //Load feature flags in
            updateFlags();

            const timeUnitlRedirect  = getExpirationDate() - Date.now()
            const timeUntil15MinuteWarning = timeUnitlRedirect-15*60*1000;
            const timeUntil5Hours = timeUnitlRedirect-5*60*60*1000;  
            const timeUntil5Days = timeUnitlRedirect-5*24*60*60*1000;  
            //Warning and Auto redirect timeouts

            let warningTimeout, redirectTimeout;
            if (timeUntil15MinuteWarning > 0) {
                warningTimeout = setTimeout(() => {
                    const message = "Your session will expire in 15 minutes. Please save your work, log out, then log back in."
                    setMessage(message);
                    openSignoutWarning(true)
                //Show a modal warning the user that the token will expire in 15 minutes
                }, timeUntil15MinuteWarning);
              }
              if (timeUntil5Hours > 0) {
                warningTimeout = setTimeout(() => {
                    const message = "Your session will expire in 5 hours. Please save your work, log out, then log back in."
                    setMessage(message);
                    openSignoutWarning(true)
                //Show a modal warning the user that the token will expire in 15 minutes
                }, timeUntil5Hours);
              }
              if (timeUntil5Days > 0) {
                warningTimeout = setTimeout(() => {
                    const message = "Your session will expire in 5 days. Please save your work, log out, then log back in"
                    setMessage(message);
                    openSignoutWarning(true)
                //Show a modal warning the user that the token will expire in 15 minutes
                }, timeUntil5Days);
              }
          
              if (timeUnitlRedirect > 0) {
                redirectTimeout = setTimeout(() => {
                    localStorage.setItem('user', null)
                    localStorage.setItem('token', null)
                    localStorage.setItem('userData', null)
                    document.cookie = "jwtToken=;path=/"
                    openSignoutWarning(false)
                    navigate("/dashboard/login")
                    //navigate("/dashboard/login")
                  // Redirect to login as if token expired
                }, timeUnitlRedirect);
              }
          
              // Clean up the timers when the component unmounts or the tokenExpirationTime changes
              return () => {
                clearTimeout(warningTimeout);
                clearTimeout(redirectTimeout);
              };
        }
        else{
            if (location.pathname !== "/" && location.pathname !== "/dashboard/barcode"   ) {
                navigate("/dashboard/login");
            }
        }
        
    }, [user]);

    const pages = [
        {
            path: "/dashboard",
            page: <Homepage user={user}/>,
            showDash: true,
            feature:"dashboard"
        },
        {
            path: "/dashboard/packing",
            page: <PackingPage />,
            showDash: true,
            feature:"packing"
        },
        {
            path: "/*",
            page: <ErrorPage />,
            showDash: false,
            feature:"dashboard"

        },
        {
            path: "/dashboard/prisync",
            page:   <PriceManagerPage />,
            showDash: true,
            feature:"prisync"
        },
        {
            path: "/dashboard/pantheon/all",
            page: <PantheonPage type={"base"} />,
            showDash: true,
            feature:"repack"
        },
        {
            path: "/dashboard/pantheon/series/:id/*",
            page: <PantheonPage type={"pantheon"} />,
            showDash: true,
            feature:"repack"
        },
        {
            path: "/dashboard/session",
            page: <Session />,
            showDash: true,
            feature:"breaking"
        },
        {
            path: "/dashboard/session/:sid/*",
            page: <BreakingPage type="breaks" />,
            showDash: true,
            feature:"breaking"
        },
        {
            path: "/dashboard/breaks/:sid/:id/*",
            page: <BreakingPage type="break" />,
            showDash: true,
            feature:"breaking"
        },
        {
            path: "/dashboard/cards",
            page: <CardsPage />,
            showDash: true,
            feature:"cards",
            role: PermissionRoles.CARDS,
        },
        {
            path: "/dashboard/uploader/*",
            page: <UploaderFirstPage />,
            showDash: true,
            feature:"uploader"
        },
        {
            path: "/dashboard/usermangement",
            page: <UserPage />,
            showDash: true,
            feature:"usermangement",
            role: PermissionRoles.ADMIN,

        },
        {
            path: "/dashboard/settings",
            page: <SettingsPage />,
            showDash: true,
            feature:"settings"
        },
        {
            path: "/dashboard/repack",
            page: <RepackLandingPage />,
            showDash: true,
            feature:"repack",
            role: PermissionRoles.REPACK,
        },
        {
            path: "/dashboard/repack/view",
            page: <ViewRepackPage />,
            showDash: true,
            feature:"repack",
            role: PermissionRoles.REPACK,
        },
        {
            path: "/dashboard/repack/create/*",
            page: <RepackCreation />,
            showDash: true,
            feature:"repack",
            role: PermissionRoles.REPACK,
        },
        {
            path: "/dashboard/sku/correction",
            page: <SkuCorrection />,
            showDash: true,
            feature:"cards",
            role: PermissionRoles.CARDS,
        },
        {
            path: "/dashboard/cards/bulk",
            page: <BulkCardCreatorPage />,
            showDash: true,
            feature:"cards",
            role: PermissionRoles.CARDS,
        },
        {
            path: "/dashboard/showHosts",
            page: <ShowHostsPage />,
            showDash: true,
            feature:"showhosts",
            role: PermissionRoles.COMMISSION,
        },
        {
            path: "/dashboard/repack/tickets",
            page: <TicketPage />,
            showDash: true,
            feature: "repack",
            role: PermissionRoles.REPACK,
        },
        {
            path: "/dashboard/shows/:uploadTaskId?",
            page: <ShowPage showType="many" />,
            showDash: true,
            feature:"shows",
            role: PermissionRoles.COMMISSION,
        },
        {
            path: "/dashboard/show/*",
            page: <ShowPage pageType="single"/>,
            showDash: true,
            feature:"shows",
            role: PermissionRoles.COMMISSION,
        },
        {
            path: "/dashboard/payperiod",
            page: <PayPeriodPage />,
            showDash: true,
            feature: "shows",
            role: PermissionRoles.COMMISSION,
        },
        {
            path: "/dashboard/payperiod/*",
            page: <SinglePayPeriodPage />,
            showDash: true,
            feature: "shows",
            role: PermissionRoles.COMMISSION,
        },
        {
            path: "/dashboard/ticker",
            page: <TickerParent />,
            showDash: true,
            feature: "ticker",
            role: PermissionRoles.COMMISSION,
        },
        {
            path: "/dashboard/stream-pc",
            page: <StreamComputerDashboard />,
            showDash: true,
            feature: "ticker",
            role: PermissionRoles.DEV,
        },
        {
            path: "/dashboard/cards/correction",
            page: <CardSyncCorrectionPage />,
            showDash: true,
            feature: "dearsync",
            role: PermissionRoles.ADMIN,
        }
    ]

    const renderPage = (element) => {
        if(flags){
            // If elements flag is valid & user has the right role, allow the user to navigate to that page.
        if(Object.keys(flags).includes(element.feature.toLowerCase()) ? flags[element.feature.toLowerCase()] : false &&
            ((element.role == undefined) ||(element.role !== undefined && hasPermission(element.role)))
        ){
            if (isLoggedIn() || element.path === "/dashboard/barcode") {
                return element.showDash ?
                    <DashboardLayout>
                        {element.page}
                    </DashboardLayout> :
                    element.page
            }
            else{
                localStorage.setItem('user', null)
                localStorage.setItem('token', null)
                localStorage.setItem('userData', null)
            }
            return <Navigate to="/dashboard/login" replace={true}/> 
        } else {
            return <Navigate to="/dashboard/" replace={true}/>
        }
        }
    
     
    }
        

    return(<Routes >
        
        <Route 
            path="/"
            element={<ProductPage link={"/Product"}/>}
        />

        <Route 
            path="/dashboard/login"
            element= {<LoginPage message={invalidationReason}/>}
        />
        <Route
            path="/dashboard/barcode"
            element={<BarcodePage />}
            
        />
        
        {pages.map(item => {
            return (<Route
                key= {"route-"+ item.path}
                path={item.path} 
                element={renderPage(item)}
            />
        )})}
    </Routes>)

}




const WarningModal = (props) => {
    return (
        <Dialog open={props.warningOpen} onClose={()=>{props.setWarningOpen(true)}}>
        <DialogTitle>{"Your session will expire"}</DialogTitle>
        <DialogContent>
            {props.warningMessage}
        </DialogContent>
        <DialogActions>
          <Button onClick = {()=>{props.setWarningOpen(false)}}>Dismiss</Button>
        </DialogActions>
      </Dialog>
    )
}


export const PageRouter = () => {

    const [isSidebarOpen, setSidebarOpen] = useLocalStorage(LocalStorageKey.DASHBOARD_SIDEBAR_OPEN, true)   
    const [warningOpen,setWarningOpen] = useState(false)
    const [warningMessage,setMessage] = useState("")

    useEffect(() => {
        verifyLocalStorage();
    },[])

    return (
        <DashboardSidebarContext.Provider value={{isSidebarOpen, setSidebarOpen}} >
            <WarningModal warningOpen={warningOpen} setWarningOpen={setWarningOpen} warningMessage={warningMessage}/>
            <BrowserRouter>
            <TrackedRouter openSignoutWarning ={setWarningOpen} setMessage={setMessage} 
            />
            </BrowserRouter>
        </DashboardSidebarContext.Provider>

    )
}

