import React, { useEffect, useRef } from "react";
import { Typography, Paper, Box } from "@mui/material";
import {
  DataGridPro,
  GridColDef,
  GridSortModel,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarQuickFilter,
  getGridStringOperators,
} from "@mui/x-data-grid-pro";
import { useBuildRepacks } from "../../../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksContext";

function CustomToolbar() {
  return (
    <GridToolbarContainer
      sx={{ p: 1, borderBottom: "1px solid rgba(224, 224, 224, 1)" }}
    >
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      <Box sx={{ flexGrow: 1 }} />
      <GridToolbarQuickFilter
        quickFilterParser={(searchInput: string) =>
          searchInput
            .split(",")
            .map((value) => value.trim())
            .filter((value) => value !== "")
        }
      />
    </GridToolbarContainer>
  );
}

function RepackTicketView() {
  const {
    tickets,
    ticketTypes,
    ticketsLoading,
    ticketsError,
    ticketsPaginationModel,
    setTicketsPaginationModel,
    totalTicketsRows,
    ticketsSortModel,
    setTicketsSortModel,
    ticketsFilteringModel: filterModel,
    setTicketsFilteringModel: setFilterModel,
    updateFilterModel,
    gridApiRef,
  } = useBuildRepacks();

  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      type: "number",
      width: 50,
      editable: false,
    },
    {
      field: "RepackTicketType",
      headerName: "Type",
      width: 150,
      editable: false,
      valueGetter: (params) => {
        const ticketType = ticketTypes?.find(
          (type: any) => type?.id === params.row?.repackTicketTypeId
        );
        return ticketType?.name || "";
      },
      filterOperators: getGridStringOperators().filter((operator) =>
        ["contains", "equals", "startsWith", "endsWith"].includes(
          operator.value
        )
      ),
    },
    {
      field: "cost",
      headerName: "Cost",
      type: "number",
      width: 75,
      editable: false,
      valueFormatter: (params) => {
        if (params.value === null || params.value === undefined || params.value === "") {
          return "";
        }
        return params.value;
      }
    },
    {
      field: "repackItemId",
      headerName: "Repack Item Id",
      type: "number",
      width: 150,
      editable: false,
    },
    {
      field: "modifiedBy",
      headerName: "Modified By",
      width: 150,
      valueGetter: (params) => params.row?.modifiedBy?.username || "",
      editable: false,
    },
    {
      field: "repackSeriesProductId",
      headerName: "Repack Series",
      width: 150,
      valueGetter: (params) => {
        return params.row?.repackSeries?.productId || "";
      },
      editable: false,
      type: "string",
    },
  ];

  const handleSortModelChange = (newModel: GridSortModel) => {
    if (!mounted.current) return;

    const transformedSortModel = newModel.map((sort) => ({
      field: sort.field,
      sort: sort.sort?.toLowerCase(),
    }));
    setTicketsSortModel(transformedSortModel);
  };

  if (ticketsError) {
    return (
      <Paper className="p-4">
        <Typography color="error">
          Error loading ticket data:{" "}
          {ticketsError.message || "Please try again later."}
        </Typography>
      </Paper>
    );
  }

  return (
    <Box
      sx={{
        height: "38vh", // Accounts for accordion header and padding
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <DataGridPro
        apiRef={gridApiRef}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row"
        }
        density="compact"
        rows={tickets || []}
        columns={columns}
        rowCount={totalTicketsRows || 0}
        loading={ticketsLoading}
        filterMode="server"
        sortingMode="server"
        paginationMode="server"
        sortModel={ticketsSortModel || []}
        onSortModelChange={handleSortModelChange}
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => {setFilterModel(newFilterModel); updateFilterModel(newFilterModel)}}
        paginationModel={ticketsPaginationModel || { page: 0, pageSize: 100 }}
        onPaginationModelChange={setTicketsPaginationModel}
        slots={{
          toolbar: CustomToolbar,
        }}
        components={{
          Toolbar: GridToolbarContainer,
        }}
        pageSizeOptions={[10, 25, 50, 100]}
        initialState={{
          sorting: {
            sortModel: [],
          },
          pagination: {
            paginationModel: { page: 0, pageSize: 100 },
          },
          filter:{
            filterModel: filterModel
          }
        }}
        sx={{
          flex: 1,
          width: "100%",
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "1px solid rgba(224, 224, 224, 1)",
          },
          "& .MuiDataGrid-row": {
            "&:nth-of-type(odd)": {
              backgroundColor: "action.hover",
            },
            "&:hover": {
              backgroundColor: "action.selected",
            },
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "1px solid rgba(224, 224, 224, 1)",
          },
        }}
      />
    </Box>
  );
}

export default RepackTicketView;
