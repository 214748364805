import React from "react";


import { Tooltip, Typography } from "@mui/material";

interface CardLadderConfidenceProps {
  value: number;
}

export const CardLadderConfidence: React.FC<CardLadderConfidenceProps> = ({ value }) => {
  const getConfidenceText = (level: number) => {
    switch (level) {
      case 5:
        return "Level 5: The \"last sold\" date occurred within the last 2 weeks.";
      case 4:
        return "Level 4: The \"last sold\" date occurred more than 2 weeks but less than 1 month ago.";
      case 3:
        return "Level 3: The \"last sold\" date occurred more than 1 month but less than 3 months ago.";
      case 2:
        return "Level 2: The \"last sold\" date occurred more than 3 months but less than 6 months ago.";
      case 1:
        return "Level 1: The \"last sold\" date occurred more than 6 months ago.";
      default:
        return "No confidence level available";
    }
  };

  return (
    <Tooltip title={getConfidenceText(value)} arrow>
      <Typography variant="body2">
        {value}
      </Typography>
    </Tooltip>
  );
};

