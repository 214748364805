import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useFilter } from "../../../Components/CommonUtility/ServerSide/useFilter";
import { LocalStorageKey } from "../../../Utilities/LocalStorage/LocalStorageEnum";
import { useUser } from "../../../Components/CommonUtility/UserContext";
import { useSnackbar } from "../../SnackbarContext/SnackbarContext";
import FetchModule from "../../../Modules/FetchModule";
import { RepackSeriesType } from "../../../Dash/RepackPage/Types/RepackSeriesType";
import { RepackBox } from "../../../Dash/RepackPage/Types/RepackBox";
import {
  RepackTicket,
  RepackTicketPrize,
} from "../../../Dash/RepackPage/Types/RepackTicket";
import useFetch from "../../../Components/CommonUtility/useFetch";
import {
  CreateNewBox,
  CreateStagedCards,
  CreateStagedTickets,
  UpdateRepackCase,
} from "./BuildRepacksAPICalls";
import { RepackItemType } from "../../../Dash/RepackPage/Types/RepackItemType";
import { RepackBoxType } from "../../../Dash/RepackPage/Types/RepackBoxType";
import { RepackCase } from "../../../Dash/RepackPage/Types/RepackCaseType";
import { RepackCaseType } from "../../../Dash/RepackPage/Types/RepackCaseType";
import { useRepackCreatorCard } from "./RepackPreviewContext";
import { Card } from "../../../Dash/SinglesPage/Types/CardType";
import { RepackTicketType } from "../../../Dash/RepackPage/Types/RepackTicketType";
import { RepackSeries } from "../../../Dash/RepackPage/Types/RepackSeries";
import usePaginationFiltering, {
  PaginationModel,
  SortModel,
} from "../../../Components/CommonUtility/usePaginationFiltering";
import { GridFilterModel } from "@mui/x-data-grid";
import { useGridApiRef } from "@mui/x-data-grid-pro";

const fetchModule = new FetchModule();

interface HandleAddTicketParams {
  ticketName: string;
  setTicketName: React.Dispatch<React.SetStateAction<string>>;
  cost: number;
  setCost: React.Dispatch<React.SetStateAction<number>>;
  multipleTickets: boolean;
  setMultipleTickets: React.Dispatch<React.SetStateAction<boolean>>;
  numberOfTickets: number;
  setNumberOfTickets: React.Dispatch<React.SetStateAction<number>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  repackSeriesId: number;
}

interface BuildRepacksContextProps {
  // Box related state
  boxTypes: RepackBoxType[];
  setBoxTypes: React.Dispatch<React.SetStateAction<RepackBoxType[]>>;
  itemTypes: RepackItemType[];
  setItemTypes: React.Dispatch<React.SetStateAction<RepackItemType[]>>;
  boxes?: RepackBox[];
  setBoxes: React.Dispatch<
    React.SetStateAction<RepackBox[] | null | undefined>
  >;
  box?: RepackBox;
  setBox: React.Dispatch<React.SetStateAction<RepackBox | null>>;
  boxLoading: boolean;
  selectedBox: RepackBox | null;
  setSelectedBox: React.Dispatch<React.SetStateAction<RepackBox | null>>;

  // Selection state
  selectedCardIds: number[];
  setSelectedCardIds: React.Dispatch<React.SetStateAction<number[]>>;
  selectedTicketIds: number[];
  setSelectedTicketIds: React.Dispatch<React.SetStateAction<number[]>>;
  selectedType: RepackSeriesType;
  setSelectedType: React.Dispatch<React.SetStateAction<RepackSeriesType>>;

  // Ticket related state
  tickets: RepackTicket[];
  setTickets: React.Dispatch<React.SetStateAction<RepackTicket[]>>;
  ticketsLoading: boolean;
  ticketsError: any;
  ticketsPaginationModel: PaginationModel;
  setTicketsPaginationModel: React.Dispatch<
    React.SetStateAction<PaginationModel>
  >;
  totalTicketsRows: any;
  ticketsSortModel: any;
  setTicketsSortModel: React.Dispatch<React.SetStateAction<any>>;
  ticketsFetching: any;
  ticketsFilteringModel: GridFilterModel;
  setTicketsFilteringModel: React.Dispatch<
    React.SetStateAction<GridFilterModel>
  >;
  updateFilterModel: any;
  getPreprocessedFilterModel: any;

  ticketTypes: RepackTicketType[];
  setTicketTypes: React.Dispatch<React.SetStateAction<RepackTicketType[]>>;

  // Series related state
  seriesList: RepackSeries[];
  setSeriesList: React.Dispatch<React.SetStateAction<RepackSeries[]>>;
  selectedSeriesId: string;
  setSelectedSeriesId: React.Dispatch<React.SetStateAction<string>>;

  // Case related state
  caseTypes: RepackCaseType[];
  setCaseTypes: React.Dispatch<React.SetStateAction<RepackCaseType[]>>;
  cases: RepackCase[];
  setCases: React.Dispatch<React.SetStateAction<RepackCase[]>>;
  selectedCase: RepackCase | null;
  setSelectedCase: React.Dispatch<React.SetStateAction<RepackCase | null>>;

  // ID management
  id: string | null;
  setId: React.Dispatch<React.SetStateAction<string | null>>;

  // Prize and ticket management
  allTickets: RepackTicket[];
  setAllTickets: React.Dispatch<React.SetStateAction<RepackTicket[]>>;
  availableTickets: RepackTicket[];
  allPrizes: RepackTicketPrize[];
  setAllPrizes: React.Dispatch<React.SetStateAction<RepackTicketPrize[]>>;
  ticketToPrizes: { [key: string]: RepackTicketPrize[] };
  setTicketToPrizes: React.Dispatch<
    React.SetStateAction<{ [key: string]: RepackTicketPrize[] }>
  >;
  gridApiRef: any;

  // Actions
  createNewBox: (boxTypeId: string, displayType: string) => Promise<void>;
  createNewCase: (data: Partial<RepackCase>) => Promise<void>;
  updateCase: (caseId: string, data: RepackCase) => Promise<void>;
  deleteCase: (caseId: string) => Promise<void>;
  addToCase: (boxIds: string[]) => Promise<void>;
  addToSelectedBox: () => void;
  addToSelectedBoxTicket: () => void;
  removeItemFromRepack: (itemId: number, boxId: number) => void;
  handleAddTicket: (params: HandleAddTicketParams) => void;

  //SERIES
  seriesLoading: any;
  seriesError: any;
  showPaginationModel: any;
  setSeriesPaginationModel: any;
  totalSeriesRows: any;
  sortModel: any;
  setSortModel: any;
  fetching: any;
  filteringModel: any;
  setFilteringModel: any;
}

const BuildRepacksContext = createContext<BuildRepacksContextProps | null>(
  null
);

type BuildRepacksProviderProps = {
  children?: React.ReactNode;
  rows?: Card[];
  setRows?: React.Dispatch<React.SetStateAction<Card[]>>;
};

const CardStateWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const cardContext = useRepackCreatorCard();

  return (
    <BuildRepacksProviderInner
      rows={cardContext.rows}
      setRows={cardContext.setRows}
    >
      {children}
    </BuildRepacksProviderInner>
  );
};

export const BuildRepacksProviderInner: React.FC<BuildRepacksProviderProps> = ({
  children,
}) => {
  // CONTEXT
  const { newSnackbarMessage } = useSnackbar();
  const { user } = useUser();
  const gridApiRef = useGridApiRef();

  // STATE

  const defaultPaginationModel: PaginationModel = { page: 0, pageSize: 100 };
  const defaultFilteringModel = { items: [], quickFilterValues: [] };
  const defaultSortModel: SortModel = null;

  const { data: boxTypes, setData: setBoxTypes } = useFetch(
    "/Repack/get/types",
    true,
    []
  );
  const { data: itemTypes, setData: setItemTypes } = useFetch(
    "/Repack/get/itemTypes",
    true,
    []
  );

  const {
    filterModel: ticketsFilteringModel,
    setFilterModel: setTicketsFilteringModel,
    updateFilterModel,
    getPreprocessedFilterModel,
  } = useFilter({
    localStorageKey: LocalStorageKey.REPACK_TICKETS_FILTER,
    gridApiRef: gridApiRef,
  });

  const {
    data: tickets,
    setData: setTickets,
    loading: ticketsLoading,
    error: ticketsError,
    paginationModel: ticketsPaginationModel,
    setPaginationModel: setTicketsPaginationModel,
    totalRows: totalTicketsRows,
    sortModel: ticketsSortModel,
    setSortModel: setTicketsSortModel,
    fetching: ticketsFetching,
  }: {
    data: Array<RepackTicket>;
    setData: any;
    loading: boolean;
    error: any;
    paginationModel: PaginationModel;
    setPaginationModel: any;
    totalRows: number;
    filteringModel: any;
    setFilteringModel: any;
    sortModel: SortModel;
    setSortModel: any;
    fetching: boolean;
  } = usePaginationFiltering(
    "/Repack/get/alltickets",
    null,
    defaultPaginationModel,
    getPreprocessedFilterModel(),
    defaultSortModel
  );

  const { data: ticketTypes, setData: setTicketTypes } = useFetch(
    "/Repack/get/tickettypes",
    true,
    []
  );

  const {
    data: seriesList,
    setData: setSeriesList,
    loading: seriesLoading,
    error: seriesError,
    paginationModel: showPaginationModel,
    setPaginationModel: setSeriesPaginationModel,
    totalRows: totalSeriesRows,
    sortModel,
    setSortModel,
    fetching,
    filteringModel,
    setFilteringModel,
  }: {
    data: Array<RepackSeries>;
    setData: any;
    loading: boolean;
    error: any;
    paginationModel: PaginationModel;
    setPaginationModel: any;
    totalRows: number;
    filteringModel: any;
    setFilteringModel: any;
    sortModel: SortModel;
    setSortModel: any;
    fetching: boolean;
  } = usePaginationFiltering(
    "/Repack/get/allseries",
    null,
    defaultPaginationModel,
    defaultFilteringModel,
    defaultSortModel
  );

  const [selectedSeriesId, setSelectedSeriesId] = useState<string>("");
  const [selectedBox, setSelectedBox] = useState<RepackBox | null>(null);

  const { data: boxes = [], setData: setBoxes } = useFetch(
    selectedSeriesId ? `/Repack/get/allstagedboxes/${selectedSeriesId}` : null,
    !!selectedSeriesId,
    []
  );

  interface TicketDataResponse {
    allTickets: RepackTicket[];
    allPrizes: RepackTicketPrize[];
    ticketToPrizes: { [key: string]: RepackTicketPrize[] };
  }

  const { data: ticketData = {} as TicketDataResponse } = useFetch(
    selectedSeriesId
      ? `/Repack/get/allstagedtickets/${selectedSeriesId}`
      : null,
    !!selectedSeriesId,
    {} as TicketDataResponse
  );

  useEffect(() => {
    if (ticketData) {
      setAllTickets(ticketData.allTickets || []);
      setAllPrizes(ticketData.allPrizes || []);
      setTicketToPrizes(ticketData.ticketToPrizes || {});
    }
  }, [ticketData]);

  const { data: caseTypes, setData: setCaseTypes } = useFetch(
    "/Repack/get/casetypes",
    true,
    []
  );

  const { data: cases, setData: setCases } = useFetch(
    selectedSeriesId ? `/Repack/get/cases/series/${selectedSeriesId}` : null,
    !!selectedSeriesId,
    []
  );

  const [selectedCase, setSelectedCase] = useState<RepackCase | null>(null);

  const {
    data: box = {},
    setData: setBox,
    loading: boxLoading,
  } = useFetch(
    selectedBox?.publicId ? `/Repack/get/box/${selectedBox.publicId}` : null,
    !!selectedBox?.publicId,
    []
  );

  useEffect(() => {
    if (boxes.length > 0) {
    }
  }, [boxes]);
  const [id, setId] = useState<string | null>(null);
  const [selectedCardIds, setSelectedCardIds] = useState<number[]>([]);
  const [selectedTicketIds, setSelectedTicketIds] = useState<number[]>([]);
  const [selectedType, setSelectedType] = useState<RepackSeriesType>(
    {} as RepackSeriesType
  );
  const [allTickets, setAllTickets] = useState<RepackTicket[]>([]);
  const [allPrizes, setAllPrizes] = useState<RepackTicketPrize[]>([]);
  const [ticketToPrizes, setTicketToPrizes] = useState<{
    [key: string]: RepackTicketPrize[];
  }>({});
  const availableTickets = useMemo(
    () => (allTickets || []).filter((ticket) => !ticket.repackItemId),
    [allTickets]
  );
  const { rows, setRows } = useRepackCreatorCard();

  const handleAddTicket = async ({
    ticketName,
    setTicketName,
    cost,
    setCost,
    multipleTickets,
    numberOfTickets,
    setNumberOfTickets,
    repackSeriesId,
    setLoading,
  }: HandleAddTicketParams) => {
    if (!ticketName || cost === 0 || isNaN(Number(cost))) {
      newSnackbarMessage("Please provide valid ticket details.", "error");
      return;
    }

    const numberOfItems = multipleTickets ? Number(numberOfTickets) : 1;

    if (
      multipleTickets &&
      (numberOfTickets === 0 ||
        isNaN(Number(numberOfTickets)) ||
        Number(numberOfTickets) <= 1)
    ) {
      newSnackbarMessage(
        "Please provide a valid number of tickets greater than 1.",
        "error"
      );
      return;
    }

    setLoading(true);

    try {
      const newTickets: RepackTicket[] = [];
      const response = await fetchModule.postResponseBody(
        "/repack/new/ticket",
        "POST",
        {
          UserId: user["id"],
          Name: ticketName,
          Cost: Number(cost),
          NumberOfTickets: numberOfItems,
          RepackSeriesId: repackSeriesId,
          RepackTicketTypeId: selectedType?.publicId,
          Prizes: [],
        }
      );

      const data = await response.json();

      for (let i = 0; i < numberOfItems; i++) {
        const newTicket: RepackTicket = {
          id: Date.now() + i,
          cost: Number(cost),
        };
        newTickets.push(newTicket);
      }

      setTickets([...tickets, ...data]);
      setAllTickets([...allTickets, ...newTickets]);

      // Refresh the tickets data after adding new tickets
      const updatedResponse = await fetchModule.postResponseBody(
        "/Repack/get/alltickets",
        {
          page: ticketsPaginationModel.page,
          pageSize: ticketsPaginationModel.pageSize,
          sort: ticketsSortModel,
          filter: ticketsFilteringModel,
        }
      );

      if (updatedResponse.ok) {
        const updatedData = await updatedResponse.json();
        setTickets(updatedData.items);
      }

      setTicketName("");
      setCost(0);
      setNumberOfTickets(0);
      newSnackbarMessage("Ticket(s) added successfully.", "success");
    } catch (error) {
      console.error("Failed to add ticket(s):", error);
      newSnackbarMessage("Failed to add ticket(s).", "error");
    } finally {
      setLoading(false);
    }
  };

  const removeItemFromRepack = useCallback(
    (itemId: number) => {
      setSelectedTicketIds((prevTickets) => {
        const item = prevTickets.find((p) => p === itemId);
        if (item) {
          setSelectedTicketIds((prev) => [...prev, item]);
        }
        return prevTickets.filter((p) => p !== itemId);
      });
    },
    [selectedCardIds]
  );
  const createNewBox = async (boxTypeId: string, displayType: string) => {
    try {
      const type = boxTypes.find(
        (type: RepackBoxType) => type.publicId === boxTypeId
      );
      if (!type) {
        newSnackbarMessage("Invalid repack type selected.", "error");
        return;
      }

      const response = await CreateNewBox(user, type, selectedSeriesId);
      if (!response.ok) {
        throw new Error("Failed to create box");
      }

      const newBoxData = await response.json();
      const newBox = newBoxData.result;

      if (displayType === "repackStorePreview") {
        if (!selectedCardIds || selectedCardIds.length === 0) {
          newSnackbarMessage(
            "Please select cards to add to the new box",
            "error"
          );
          return;
        }

        const selectedCardObjects = selectedCardIds.map((selectedId) => {
          const cardObject = rows.find((card) => card.id === selectedId);
          if (!cardObject) {
            throw new Error(`Card not found for id: ${selectedId}`);
          }
          return cardObject;
        });

        const newCardResults = await Promise.all(
          selectedCardObjects.map(async (card) => {
            const response = await CreateStagedCards(
              card,
              user,
              newBox.publicId
            );
            if (!response.ok) {
              throw new Error("Failed to create staged card");
            }
            const data = await response.json();
            return data.result;
          })
        );

        const updatedBox = {
          ...newBox,
          repackItems: newCardResults,
        };

        setBoxes((prev: RepackBox[]) =>
          prev ? [...prev, updatedBox] : [updatedBox]
        );
        setRows((prevCards) =>
          prevCards.filter((card) => !selectedCardIds.includes(card.id))
        );
        setSelectedCardIds([]);
        newSnackbarMessage(
          "Box created and cards added successfully",
          "success"
        );
      } else {
        if (!selectedTicketIds?.length) {
          newSnackbarMessage(
            "Please select tickets to add to the new box",
            "error"
          );
          return;
        }

        const newTicketsResults = await Promise.all(
          selectedTicketIds.map(async (ticketId) => {
            const ticket = availableTickets.find(
              (t) => t.id === ticketId || Number(t.id) === ticketId
            );

            if (!ticket) {
              throw new Error(`Ticket not found for id: ${ticketId}`);
            }

            const response = await CreateStagedTickets(
              ticket,
              user,
              newBox.publicId
            );
            if (!response.ok) {
              throw new Error("Failed to create staged ticket");
            }
            const data = await response.json();
            return data.result;
          })
        );

        const updatedBox = {
          ...newBox,
          repackItems: newTicketsResults,
        };

        setBoxes((prev: RepackBox[]) =>
          prev ? [...prev, updatedBox] : [updatedBox]
        );
        setAllTickets((prevTickets) =>
          prevTickets.map((ticket) => {
            const matchingResult = newTicketsResults.find(
              (result) => result.repackTicket?.publicId === ticket.publicId
            );
            if (matchingResult) {
              return {
                ...ticket,
                repackItemId: matchingResult.id,
              };
            }
            return ticket;
          })
        );

        setSelectedTicketIds([]);
        newSnackbarMessage(
          "Box created and tickets added successfully",
          "success"
        );
      }
    } catch (error) {
      console.error("Error creating box:", error);
      newSnackbarMessage("Failed to create box", "error");
    }
  };

  const createNewCase = async (data: Partial<RepackCase>) => {
    try {
      const { repackCaseTypeId, repackSeriesId, isStaged } = data;

      const response = await fetchModule.postResponseBody(
        "/Repack/new/case",
        "POST",
        {
          Body: {
            RepackCaseTypeId: repackCaseTypeId,
            RepackSeriesId: repackSeriesId,
            IsStaged: isStaged,
          },
          UserId: user["id"],
        }
      );

      if (response.ok) {
        const newCase = await response.json();
        setCases((prev: RepackCase[]) => [...prev, newCase]);
        newSnackbarMessage("Case created successfully", "success");
      } else {
        throw new Error("Failed to create case");
      }
    } catch (error) {
      console.error("Error creating case:", error);
      newSnackbarMessage("Failed to create case", "error");
    }
  };

  const updateCase = async (caseId: string, caseData: RepackCase) => {
    try {
      const response = await UpdateRepackCase(caseData, user);

      if (response.ok) {
        const updatedCase = await response.json();
        setCases((prev: RepackCase[]) =>
          prev.map((c: RepackCase) => (c.publicId === caseId ? updatedCase : c))
        );
        newSnackbarMessage("Case updated successfully", "success");
      } else {
        throw new Error("Failed to update case");
      }
    } catch (error) {
      console.error("Error updating case:", error);
      newSnackbarMessage("Failed to update case", "error");
    }
  };

  const deleteCase = async (caseId: string) => {
    try {
      const response = await fetchModule.postResponseBody(
        "/Repack/delete/case",
        "DELETE",
        {
          Guid: caseId,
          UserId: user["id"],
        }
      );

      if (response.ok) {
        const result = await response.json();
        setCases((prev: RepackCase[]) =>
          prev.filter((c: RepackCase) => c.publicId !== caseId)
        );

        setBoxes((prev: RepackBox[]) => {
          const updatedBoxes = [...prev];
          result.boxIds.forEach((boxId: string) => {
            const boxIndex = updatedBoxes.findIndex(
              (b) => b.publicId === boxId
            );
            if (boxIndex !== -1) {
              updatedBoxes[boxIndex] = {
                ...updatedBoxes[boxIndex],
                repackCaseId: undefined,
              };
            }
          });
          return updatedBoxes;
        });

        newSnackbarMessage("Case deleted successfully", "success");
      } else {
        throw new Error("Failed to delete case");
      }
    } catch (error) {
      console.error("Error deleting case:", error);
      newSnackbarMessage("Failed to delete case", "error");
    }
  };

  const addToCase = async (boxIds: string[]) => {
    if (!selectedCase) {
      newSnackbarMessage("No case selected", "error");
      return;
    }

    try {
      const response = await fetchModule.postResponseBody(
        "/Repack/update/case",
        "POST",
        {
          CaseId: selectedCase.publicId,
          BoxIds: boxIds,
          UserId: user["id"],
        }
      );

      if (response.ok) {
        const updatedCase = await response.json();
        setCases((prev: RepackCase[]) =>
          prev.map((c: RepackCase) =>
            c.publicId === selectedCase.publicId ? updatedCase : c
          )
        );
        setBoxes((prev: RepackBox[]) =>
          prev.map((b: RepackBox) =>
            b.publicId && boxIds.includes(b.publicId)
              ? { ...b, repackCaseId: selectedCase.id }
              : b
          )
        );
        newSnackbarMessage("Boxes added to case successfully", "success");
      } else {
        throw new Error("Failed to add boxes to case");
      }
    } catch (error) {
      console.error("Error adding boxes to case:", error);
      newSnackbarMessage("Failed to add boxes to case", "error");
    }
  };

  const addToSelectedBox = async () => {
    if (!selectedBox) {
      newSnackbarMessage(
        "No valid selected box found. Make sure you've selected the box you'd like to add items to.",
        "error"
      );
      console.error("No valid selected box found.");
      return;
    }

    const selectedBoxId = selectedBox.publicId;

    try {
      const newCardsResults = await Promise.all(
        selectedCardIds.map(async (selectedId) => {
          const card = rows.find((c: Card) => c.id === selectedId);

          if (!card) {
            throw new Error(`Card not found for id: ${selectedId}`);
          }

          if (!selectedBoxId) {
            throw new Error("Selected box ID is undefined");
          }
          const result = await CreateStagedCards(card, user, selectedBoxId);
          if (!result.ok) {
            throw new Error(
              `Failed to create staged card: ${result.statusText}`
            );
          }
          const data = await result.json();
          return data.result;
        })
      );

      setSelectedBox((prevBox) => {
        if (!prevBox) return prevBox;
        const currentItems = prevBox.repackItems || [];
        return {
          ...prevBox,
          repackItems: [...currentItems, ...newCardsResults],
        };
      });

      setBoxes((prevBoxes: RepackBox[]) =>
        prevBoxes.map((box: RepackBox) =>
          box.publicId === selectedBoxId
            ? {
                ...box,
                repackItems: [...(box.repackItems || []), ...newCardsResults],
              }
            : box
        )
      );

      setBox((prevBox: RepackBox) => {
        if (!prevBox) return prevBox;
        return {
          ...prevBox,
          repackItems: [...(prevBox.repackItems || []), ...newCardsResults],
        };
      });

      setRows((prevCards: Card[]) =>
        prevCards.filter((card) => !selectedCardIds.includes(card.id))
      );

      setSelectedCardIds([]);
      newSnackbarMessage("Items successfully added to the box.", "success");
    } catch (error) {
      console.error("Error adding items to the box:", error);
      newSnackbarMessage("Failed to add items to the box.", "error");
    }
  };

  const addToSelectedBoxTicket = async () => {
    if (!selectedBox || !selectedBox.publicId) {
      newSnackbarMessage(
        "No valid selected box found. Make sure you've selected the box you'd like to add the tickets to.",
        "error"
      );
      console.error("No valid selected box found.");
      return;
    }

    const selectedBoxId = selectedBox.publicId;

    try {
      const newTicketsResults = await Promise.all(
        selectedTicketIds.map(async (ticketId) => {
          const ticket = availableTickets.find(
            (t) => t.id === ticketId || Number(t.id) === ticketId
          );

          if (!ticket) {
            throw new Error(`Ticket not found for id: ${ticketId}`);
          }

          const response = await CreateStagedTickets(
            ticket,
            user,
            selectedBox?.publicId || ""
          );

          if (!response.ok) {
            throw new Error(
              `Failed to create staged ticket: ${response.statusText}`
            );
          }

          const responseJson = await response.json();
          return {
            ...responseJson.result,
            originalTicketId: ticket.id,
          };
        })
      );

      const boxUpdates = {
        items: [...(selectedBox.repackItems || []), ...newTicketsResults],
      };

      setSelectedBox((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          repackItems: boxUpdates.items,
        };
      });

      setBox((prev: RepackBox) => ({
        ...prev,
        repackItems: boxUpdates.items,
      }));

      setBoxes((prevBoxes: RepackBox[]) =>
        prevBoxes.map((box) =>
          box.publicId === selectedBoxId
            ? { ...box, repackItems: boxUpdates.items }
            : box
        )
      );

      setAllTickets((prevTickets) =>
        prevTickets.map((ticket) => {
          const matchingResult = newTicketsResults.find(
            (result) => result.originalTicketId === ticket.id
          );

          if (matchingResult) {
            return {
              ...ticket,
              repackItemId: matchingResult.id,
              repackItem: matchingResult,
            };
          }
          return ticket;
        })
      );

      setSelectedTicketIds([]);
      newSnackbarMessage("Tickets successfully added to the box.", "success");
    } catch (error) {
      console.error("Error adding tickets to the box:", error);
      newSnackbarMessage("Failed to add tickets to the box.", "error");
    }
  };

  const contextValues = {
    boxTypes,
    setBoxTypes,
    itemTypes,
    setItemTypes,
    boxes,
    setBoxes,
    selectedBox,
    setSelectedBox,
    boxLoading,
    selectedSeriesId,
    setSelectedSeriesId,
    box,
    setBox,
    selectedCardIds,
    setSelectedCardIds,
    selectedTicketIds,
    setSelectedTicketIds,
    selectedType,
    setSelectedType,
    handleAddTicket,
    addToSelectedBox,
    addToSelectedBoxTicket,
    tickets,
    setTickets,
    ticketsLoading,
    ticketsError,
    ticketsPaginationModel,
    setTicketsPaginationModel,
    totalTicketsRows,
    ticketsSortModel,
    setTicketsSortModel,
    ticketsFetching,
    ticketsFilteringModel,
    setTicketsFilteringModel,
    updateFilterModel,
    getPreprocessedFilterModel,
    removeItemFromRepack,
    id,
    setId,
    ticketTypes,
    setTicketTypes,
    seriesList,
    setSeriesList,
    caseTypes,
    setCaseTypes,
    cases,
    setCases,
    selectedCase,
    setSelectedCase,
    createNewBox,
    createNewCase,
    updateCase,
    deleteCase,
    addToCase,
    allTickets,
    setAllTickets,
    availableTickets,
    allPrizes,
    setAllPrizes,
    ticketToPrizes,
    setTicketToPrizes,
    seriesLoading,
    seriesError,
    showPaginationModel,
    setSeriesPaginationModel,
    totalSeriesRows,
    sortModel,
    setSortModel,
    fetching,
    filteringModel,
    setFilteringModel,
    gridApiRef
  };

  return (
    <BuildRepacksContext.Provider value={contextValues}>
      {children}
    </BuildRepacksContext.Provider>
  );
};

export const BuildRepacksProvider: React.FC<BuildRepacksProviderProps> = ({
  children,
}) => {
  return <CardStateWrapper>{children}</CardStateWrapper>;
};

// Hook to use the context
export function useBuildRepacks() {
  const context = useContext(BuildRepacksContext);
  if (!context) {
    throw new Error(
      "Invalid BuildRepacksContext, must be wrapped in a BuildRepacksProvider"
    );
  }
  return context;
}
