import { TextField } from "@mui/material";
import { GridEditCellProps } from "@mui/x-data-grid-pro";

export const CompInput = (params: GridEditCellProps) => {
  const { id, value, api } = params;

  const handleComp = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const newValue = inputValue === '' ? 0 : parseFloat(inputValue);
    
    if (!isNaN(newValue)) {

      await api.setEditCellValue({
        id,
        field: "comp",
        value: newValue,
      });

      
    }
  };

  const getDisplayValue = () => {
    const displayValue = typeof value === 'object' ? value.value : value;
    return displayValue;
  }

  return (
    <TextField
      value={getDisplayValue()}
      onChange={handleComp}
      type="number"
      inputProps={{ step: "any" }}
    />
  );
};